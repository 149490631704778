import { Log, User, UserManager, WebStorageStateStore } from 'oidc-client-ts'
import { createContext, createRef } from 'react'

export const AuthContextRef = createRef<{
  accessToken: string | null
  setAccessToken: React.Dispatch<React.SetStateAction<string | null>>
}>()

export const AuthContext = createContext({
  accessToken: null,
  setAccessToken: () => {},
} as {
  accessToken: string | null
  setAccessToken: React.Dispatch<React.SetStateAction<string | null>>
})
