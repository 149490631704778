import { useQuery } from '@apollo/client'
import type { DocumentNode, QueryResult } from '@apollo/client'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertIcon,
  Box,
  Card,
  CardHeader,
  CardBody,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Input,
  ListItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
  SimpleGrid,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useBreakpointValue,
  VStack,
  UnorderedList,
} from '@chakra-ui/react'
import { ReactElement, useState, useMemo } from 'react'
import useDebounce from '../hooks/useDebounce'

import { cardNamesByTitleQuery } from '../Queries'

export default function ({
  title,
  cards,
  addCard,
  removeSlug,
  clearSlugs,
}: {
  title: string
  cards: { slug: string; title: string }[]
  addCard: (card: { slug: string; title: string }) => void
  removeSlug: (slug: string) => void
  clearSlugs: () => void
}) {
  const [bouncySearchParam, setSearchParam] = useState('')
  const searchInput = useDebounce(bouncySearchParam, 200)

  const searchResults = useQuery(cardNamesByTitleQuery, {
    variables: {
      title: searchInput,
      first: 20,
    },
    skip: searchInput.length < 3,
  })
  const autoCompleteOptions =
    searchResults?.data?.cards?.nodes?.map(
      (card: { front: { title: string }; slug: string }) => ({
        title: card.front.title,
        slug: card.slug,
      })
    ) ?? []

  return (
    <FormControl color="white">
      <FormLabel>{title}</FormLabel>

      <Box>
        <Input
          size="sm"
          value={bouncySearchParam}
          onChange={(event) => setSearchParam(event.target.value)}
        />
        <Box>
          <UnorderedList
            styleType="none"
            bg="blue.900"
            mx="0"
            borderBottomRadius={4}
          >
            {searchResults?.data?.cards?.nodes?.map(
              (card: { front: { title: string }; slug: string }) => (
                <ListItem
                  onClick={() => {
                    setSearchParam('')
                    addCard({ slug: card.slug, title: card.front.title })
                  }}
                  cursor="pointer"
                  px="4"
                  py="1"
                  _hover={{ bg: 'blue.800' }}
                >
                  {card.front.title}{' '}
                  <Text display="inline" color="gray.500" fontSize="sm" ml="2">
                    ({card.slug})
                  </Text>
                </ListItem>
              )
            )}
          </UnorderedList>
        </Box>
      </Box>

      {cards.length > 0 && (
        <FormHelperText color="white">
          <HStack>
            {cards.map((x) => (
              <Tag variant="subtle" colorScheme="gray">
                <TagLabel>{x.title}</TagLabel>
                <TagCloseButton onClick={() => removeSlug(x.slug)} />
              </Tag>
            ))}
          </HStack>
        </FormHelperText>
      )}
    </FormControl>
  )
}
