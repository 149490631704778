import { Box, Grid, GridItem, HStack, Show, Tag, Text } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'

import SphereTag from '../../components/SphereTag'
import { typeLookup } from '../../helpers/enumLookups'
import type { CardVersionWithCard } from '../../Types'

function ThinSpace() {
  return <span style={{ fontSize: '0.2em' }}> </span>
}

function ProductCardChecklist({ cards }: { cards: CardVersionWithCard[] }) {
  return (
    <Grid
      autoFlow="row"
      rowGap="2"
      columnGap="8"
      gridAutoFlow="dense"
      alignItems="baseline"
      sx={{
        '@media print': {
          rowGap: 0,
          fontSize: '8pt',
        },
      }}
    >
      {cards
        .slice()
        .sort((a, b) => parseInt(a.number) - parseInt(b.number))
        .map((cardVersion, index) => (
          <>
            <GridItem
              colStart={{
                base: 1,
                '2xl': index > cards.length / 2 ? 2 : 1,
              }}
              sx={{
                '@media print': {
                  gridColumnStart: index > cards.length / 2 ? 2 : 1,
                },
              }}
            >
              <HStack alignItems="center">
                <Box
                  display="none"
                  sx={{
                    '@media print': {
                      display: 'block',
                    },
                  }}
                >
                  ☐
                </Box>
                <Box
                  width="40px"
                  flexShrink={0}
                  fontFamily="monospace"
                  whiteSpace="pre"
                  sx={{ 'word-spacing': '0.05em' }}
                >
                  #<ThinSpace />
                  {cardVersion.number.padStart(3, ' ')}
                </Box>
                <Box
                  width="25px"
                  flexShrink={0}
                  fontFamily="monospace"
                  textAlign="right"
                  sx={{ 'word-spacing': '0.05em' }}
                >
                  {cardVersion.quantity}
                  <ThinSpace />x
                </Box>
                <Box
                  pl={1}
                  flexGrow={1}
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  <NavLink to={`/card/${cardVersion.card.slug}`}>
                    <Text
                      color={
                        cardVersion.frontImageUrl.startsWith('http') ||
                        (cardVersion.backImageUrl?.startsWith('http') ?? false)
                          ? 'red'
                          : 'black'
                      }
                    >
                      {cardVersion.card.front.title}
                    </Text>
                  </NavLink>
                </Box>
                <Show above="sm">
                  <Box flexShrink={0} textAlign="right">
                    <Tag size="sm">
                      {typeLookup(cardVersion.card.front.type)}
                    </Tag>{' '}
                    {cardVersion.card.front.sphere && (
                      <SphereTag
                        sphere={cardVersion.card.front.sphere}
                        iconSize={12}
                        size="sm"
                      />
                    )}
                  </Box>
                </Show>
              </HStack>
            </GridItem>
            {cardVersion.backNumber && (
              <GridItem
                colStart={{
                  base: 1,
                  '2xl': index > cards.length / 2 ? 2 : 1,
                }}
                sx={{
                  '@media print': {
                    gridColumnStart: index > cards.length / 2 ? 2 : 1,
                  },
                }}
              >
                <HStack alignItems="center" color="gray.400">
                  <Box display="none">☐</Box>
                  <Box
                    width="50px"
                    flexShrink={0}
                    fontFamily="monospace"
                    whiteSpace="pre"
                    textAlign="right"
                  >
                    # {cardVersion.backNumber.padStart(3, ' ')}
                  </Box>
                  <Box
                    width="15px"
                    flexShrink={0}
                    fontFamily="monospace"
                    textAlign="right"
                  ></Box>
                  <Box
                    pl={1}
                    flexGrow={1}
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    Back side:
                    <NavLink to={`/card/${cardVersion.card.slug}`}>
                      {cardVersion.card.back?.title}
                    </NavLink>
                  </Box>
                  <Box width="70px" flexShrink={0} textAlign="right">
                    {cardVersion.card.back?.sphere && (
                      <SphereTag
                        sphere={cardVersion.card.back?.sphere}
                        iconSize={12}
                        size="sm"
                      />
                    )}
                  </Box>
                  <Box width="100px" flexShrink={0}>
                    <Tag size="sm">
                      {typeLookup(cardVersion.card.front.type)}
                    </Tag>
                  </Box>
                </HStack>
              </GridItem>
            )}
          </>
        ))}
    </Grid>
  )
}

export default ProductCardChecklist
