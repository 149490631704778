import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import type { StyleFunctionProps } from '@chakra-ui/styled-system'
import { mode } from '@chakra-ui/theme-tools'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import './index.css'
import './fonts.css'
//import reportWebVitals from './reportWebVitals'

import ApolloProvider from './apollo/ApolloProvider'
import App from './App'
import AuthProvider from './auth/AuthProvider'
import SigninCallback from './auth/SigninCallback'
import { initMatomo } from './matomo'
import CardDetails from './pages/card-details/CardDetails'
import SimpleSearch from './pages/card-simple-search/SimpleSearch'
import DeckSearch from './pages/deck-search'
import ManageCollection from './pages/manage-collection/ManageCollection'
import ManageProfile from './pages/manage-profile/ManageProfile'
import ProductListing from './pages/products-listing'
import UserProvider from './user/UserProvider'

const chakraTheme = extendTheme({
  colors: {
    brand: {
      50: '#dff5ff',
      100: '#b4dffd',
      200: '#88c9f6',
      300: '#5bb3f1',
      400: '#319dec',
      500: '#1a84d2',
      600: '#0e66a4',
      700: '#044976',
      800: '#002c49',
      900: '#00101d',
    },
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        bg: mode('gray.50', 'gray.800')(props),
      },
    }),
  },
})

const reactRouter = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        index: true,
        element: <Navigate replace to="/cards" />,
      },
      {
        path: '/cards',
        children: [
          {
            index: true,
            element: <SimpleSearch />,
          },
          // {
          //   path: 'advanced',
          //   element: <h1>ADVANCED SEARCH</h1>,
          // },
        ],
      },
      {
        path: '/card/:slug',
        element: <CardDetails />,
      },
      {
        path: '/products',
        element: <ProductListing />,
      },
      {
        path: '/decks',
        element: <DeckSearch />,
      },
      {
        path: '/oauth2callback',
        element: <SigninCallback />,
      },
      {
        path: '/profile',
        element: <ManageProfile />,
      },
      {
        path: '/collection',
        element: <ManageCollection />,
      },
    ],
  },
])

initMatomo('https://sherlock.cardgame.tools', '1')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <AuthProvider>
      <ApolloProvider>
        <UserProvider>
          <ChakraProvider theme={chakraTheme}>
            <RouterProvider router={reactRouter} />
          </ChakraProvider>
        </UserProvider>
      </ApolloProvider>
    </AuthProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
