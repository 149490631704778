import { SimpleGrid } from '@chakra-ui/react'

import LotrCard from '../../components/LotrCard'
import { CardVersionWithCard, CardWithVersions } from '../../Types'

function CardDetailsGrid({ cards }: { cards: CardWithVersions[] }) {
  return (
    <SimpleGrid
      key="grid"
      minChildWidth={{ base: 'none', md: '650px' }}
      spacingX="12"
      spacingY="4"
      ml={{ base: 0, lg: 4 }}
    >
      {cards.map((card) => (
        <LotrCard key={card.slug} card={card} />
      ))}
    </SimpleGrid>
  )
}

export default CardDetailsGrid
