import { useApolloClient, useQuery } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import { ReactNode, useContext, useEffect, useState } from 'react'

import { AuthContext } from '../auth/AuthContext'
import useDebounce from '../hooks/useDebounce'
import usePrevious from '../hooks/usePrevious'
import { setUserId, resetUserId } from '../matomo'
import { userQuery } from '../Queries'
import { UserContext } from './UserContext'

const UserProvider = ({ children }: { children?: ReactNode }) => {
  const [username, setUsername] = useState(null as string | null)
  const { accessToken } = useContext(AuthContext)

  const { data } = useQuery(userQuery, {
    skip: !accessToken,
  })

  useEffect(() => {
    const username = data?.user?.profile?.username ?? null
    setUsername(username)

    if (username) setUserId(username)
    else resetUserId()
  }, [data])

  const prevAccessToken = usePrevious(accessToken)
  const toast = useToast()

  if (accessToken == null && prevAccessToken != null) {
    if (!toast.isActive('signout')) {
      toast({
        id: 'signout',
        title: 'Signed out',
        description: 'You have been signed out.',
        status: 'warning',
        position: 'bottom-right',
        duration: null,
        isClosable: true,
      })
    }
  }

  const apolloClient = useApolloClient()

  const debouncedAccessToken = useDebounce(accessToken, 100)

  useEffect(() => {
    apolloClient.refetchQueries({ include: [userQuery] })
  }, [debouncedAccessToken, apolloClient])

  return (
    <UserContext.Provider value={username}>{children}</UserContext.Provider>
  )
}

export default UserProvider
