import { useQuery } from '@apollo/client'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Tag,
  TagLabel,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { ReactElement, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import SphereTag from '../../components/SphereTag'
import StatTag from '../../components/StatTag'
import TypeTag from '../../components/TypeTag'
import { formatCardText, formatNumber } from '../../helpers/textReplacements'
import { deckSearchQuery, randomDecksQuery } from '../../Queries'
import { ReactComponent as Threat } from '../../svgs/Threat.svg'
import { Deck } from '../../Types'
import SearchCriteria from './searchCriteria'

function ThinSpace() {
  return <span style={{ fontSize: '0.2em' }}> </span>
}

function typeSort(typeA: string, typeB: string) {
  const sortOrder = ['Hero', 'Ally', 'Attachment', 'Event']
  return sortOrder.indexOf(typeA) - sortOrder.indexOf(typeB)
}

function sphereSort(sphereA: string, sphereB: string) {
  return sphereA.localeCompare(sphereB)
}

function DeckBox({ deck }: { deck: Deck }) {
  const imageProps = {
    maxHeight: '250px',
    maxWidth: '240px',
    borderRadius: 14,
    boxShadow:
      '0px 3px 8px 0px rgba(45,55,72,0.75), 0px 0px 3px 2px rgba(255,255,255,0.25)',
    position: 'absolute' as any,
  }

  const imageTransforms = [
    {
      left: '10px',
      transform: 'rotate(-4deg)',
      top: '6px',
    },
    {
      left: '180px',
      transform: 'rotate(-1deg)',
      top: '0',
    },
    {
      left: '350px',
      transform: 'rotate(2deg)',
      top: '4px',
    },
  ]

  const heroes = deck.cards.filter((c) => c.card.front.type === 'HERO')

  const images = heroes.map((c, i) => (
    <Image
      key={c.card.slug}
      src={
        `https://images.cardgame.tools/lotr/sm/${c.card.versions[0]?.frontImageUrl}` ??
        ''
      }
      {...imageProps}
      {...imageTransforms[i]}
    />
  ))

  return (
    <Box>
      <Box height={'145px'} position="relative">
        {images.map((image) => image)}
      </Box>
      <Card>
        <CardBody>
          <Heading fontFamily="ringbearer" mb={4} fontSize="3xl">
            {deck.name}
          </Heading>
          <Flex columnGap={6} rowGap={2} mb={4} wrap="wrap">
            {heroes.map((c) => (
              <Text>
                <SphereTag sphere={c.card.front.sphere as string} />{' '}
                {c.card.front.title}
              </Text>
            ))}
          </Flex>

          <Flex columnGap={6} rowGap={2} mb={4} wrap="wrap">
            <Tag>
              <Text
                fontFamily="vafthrudnir"
                position="relative"
                top="4px"
                mr={1}
              >
                {formatNumber(28)}
              </Text>
              <Threat width="16px" height="16px" />
            </Tag>
            <Tag>
              {deck.cards.reduce((acc, s) => acc + s.quantity, 0)} cards
            </Tag>

            <NavLink
              target="_blank"
              to={`https://ringsdb.com/decklist/view/${deck.id}`}
            >
              <Button size="sm" colorScheme="yellow">
                View on RingsDB <Icon ml="2" as={ExternalLinkIcon} />
              </Button>
            </NavLink>

            {/* <Tag variant="outline" colorScheme="blue">
              RCO ONLY!
            </Tag>
            <Tag variant="outline" colorScheme="green">
              You can build this deck!
            </Tag>
            <Tag variant="outline" colorScheme="red">
              You can't build this deck
            </Tag> */}
          </Flex>
          <Grid
            autoFlow="row"
            rowGap="2"
            columnGap="2"
            gridAutoFlow="dense"
            alignItems="baseline"
            templateColumns="auto auto 1fr"
            alignSelf="start"
            sx={{
              '@media print': {
                rowGap: 0,
                fontSize: '8pt',
              },
            }}
          >
            {deck.cards
              .slice()
              .sort(
                (a, b) =>
                  sphereSort(
                    a.card.front.sphere ?? '',
                    b.card.front.sphere ?? ''
                  ) || typeSort(a.card.front.type, b.card.front.type)
              )
              .map((card) => (
                <>
                  <GridItem>
                    <Box
                      width="25px"
                      flexShrink={0}
                      fontFamily="monospace"
                      textAlign="right"
                      sx={{ 'word-spacing': '0.05em' }}
                    >
                      {card.quantity}
                      <ThinSpace />x
                    </Box>
                  </GridItem>
                  <GridItem>
                    <SphereTag
                      sphere={card.card.front.sphere?.toUpperCase()!}
                      iconSize={16}
                    />
                  </GridItem>
                  <GridItem>
                    <Flex wrap="wrap">
                      <Text
                        flexGrow={1}
                        fontWeight={
                          card.card.front.type === 'Hero' ? 'bold' : 'normal'
                        }
                      >
                        <NavLink to={`/card/${card.card.slug}`}>
                          {card.card.front.isUnique && (
                            <Image
                              src="/icons/Unique.svg"
                              boxSize="14px"
                              position="relative"
                              top="2px"
                              display="inline"
                              mr={1}
                            />
                          )}
                          {card.card.front.title}
                        </NavLink>
                      </Text>
                      <HStack ml="auto">
                        <TypeTag
                          type={card.card.front.type.toUpperCase()}
                          subtype={undefined}
                        />
                      </HStack>
                    </Flex>
                  </GridItem>
                </>
              ))}
          </Grid>
        </CardBody>
      </Card>
    </Box>
  )
}

export default DeckBox
