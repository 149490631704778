function StatIconUrl(stat: string) {
  switch (stat) {
    case 'ATTACK':
      return '/icons/Attack.svg'
    case 'DEFENSE':
      return '/icons/Defense.svg'
    case 'HITPOINTS':
      return '/icons/HitPoints.svg'
    case 'THREAT':
      return '/icons/Threat.svg'
    case 'WILLPOWER':
      return '/icons/Willpower.svg'
  }

  return ''
}

export default StatIconUrl
