import type { QueryResult } from '@apollo/client'
import {
  Alert,
  AlertIcon,
  Button,
  Card,
  Center,
  Flex,
  Select,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'

import useLocalStorage from '../../hooks/useLocalStorage'
import { CardWithVersions } from '../../Types'
import CardDetailsGrid from './CardDetailsGrid'
import CardImageGrid from './CardImageGrid'

export default function ({
  showRandomCards,
  results,
}: {
  showRandomCards: boolean
  results:
    | QueryResult<
        any,
        {
          title: any
          first: number
        }
      >
    | QueryResult<any, {}>
}) {
  const totalCount = results.data?.cards.totalCount ?? 0
  const hasNextPage = results.data?.cards.pageInfo?.hasNextPage ?? false
  const error = results.error
  const loading = results.loading
  const cards = (results.data?.cards.nodes ?? []) as CardWithVersions[]

  const [viewMode, setViewMode] = useLocalStorage(
    'cardSearchVewMode',
    'detailsGrid' as 'detailsGrid' | 'imageGrid'
  )

  let content = []

  if (error) content.push(<p>Error : {error.message}</p>)
  else if (loading && cards?.length === 0)
    content.push(
      <Center key="spinner" my={12} zIndex="900">
        <Spinner color="white" size="xl" />
      </Center>
    )
  else {
    content.push(
      <Card
        key="topcard"
        p="2"
        bg="gray.500"
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        <Flex
          wrap="wrap"
          justifyContent="flex-start"
          alignItems="center"
          gap="4"
        >
          <Select
            size="sm"
            variant="filled"
            flexGrow="0"
            width={{ base: '100%', md: 'auto' }}
            value={viewMode}
            onChange={(e) =>
              setViewMode(e.target.value as 'detailsGrid' | 'imageGrid')
            }
          >
            <option value="detailsGrid">View as card details</option>
            <option value="imageGrid">View as card images</option>
          </Select>
          <Text px={2} width={{ base: '100%', md: 'auto' }} color="white">
            {showRandomCards
              ? 'A few random cards to get you inspired...'
              : `Found ${totalCount} cards.`}
          </Text>
        </Flex>
      </Card>
    )

    content.push(
      {
        detailsGrid: <CardDetailsGrid key="carddetailsgrid" cards={cards} />,
        imageGrid: <CardImageGrid key="CardImageGrid" cards={cards} />,
      }[viewMode]
    )

    if (hasNextPage && !loading) {
      content.push(
        <Center key="loadmore" mb={20}>
          <Button
            colorScheme="purple"
            onClick={() => {
              results.fetchMore({
                variables: {
                  after: results.data.cards?.pageInfo?.endCursor,
                },
              })
            }}
          >
            Load more
          </Button>
        </Center>
      )
    }
  }

  return (
    <VStack spacing="8" alignItems="stretch" minHeight="100vh" p={8}>
      {content}
    </VStack>
  )
}
