import { ProductType } from '../Types'

function sphereLookup(sphere: string) {
  return {
    BAGGINS: 'Baggins',
    FELLOWSHIP: 'Fellowship',
    LEADERSHIP: 'Leadership',
    LORE: 'Lore',
    MASTERY: 'Mastery',
    NEUTRAL: 'Neutral',
    SPIRIT: 'Spirit',
    TACTICS: 'Tactics',
  }[sphere]
}

function sphereColors(sphere: string): { bg: string; color: string } {
  if (!sphere) return { bg: 'gray', color: 'black' }

  return {
    BAGGINS: { bg: 'yellow', color: 'black' },
    FELLOWSHIP: { bg: 'orange', color: 'black' },
    LEADERSHIP: { bg: 'purple', color: 'white' },
    LORE: { bg: 'green', color: 'white' },
    MASTERY: { bg: 'orange', color: 'black' },
    NEUTRAL: { bg: 'gray', color: 'black' },
    SPIRIT: { bg: 'cyan', color: 'black' },
    TACTICS: { bg: 'red', color: 'white' },
  }[sphere]!
}

function sphereIconUrl(sphere: string): string | undefined {
  return {
    BAGGINS: '/icons/Baggins.svg',
    FELLOWSHIP: '/icons/Fellowship.svg',
    LEADERSHIP: '/icons/Leadership.svg',
    LORE: '/icons/Lore.svg',
    MASTERY: '/icons/Mastery.svg',
    NEUTRAL: undefined,
    SPIRIT: '/icons/Spirit.svg',
    TACTICS: '/icons/Tactics.svg',
  }[sphere]!
}

function typeLookup(type: string) {
  return {
    ALLY: 'Ally',
    ATTACHMENT: 'Attachment',
    CONTRACT: 'Contract',
    EVENT: 'Event',
    HERO: 'Hero',
    PLAYER_SIDE_QUEST: 'Player side quest',
    TREASURE: 'Treasure',

    CAMPAIGN: 'Campaign',
    CAVE: 'Cave',
    ENCOUNTER_SIDE_QUEST: 'Encounter side quest',
    ENEMY: 'Enemy',
    LOCATION: 'Location',
    OBJECTIVE: 'Objective',
    OBJECTIVE_ALLY: 'Objective ally',
    OBJECTIVE_HERO: 'Objective hero',
    OBJECTIVE_LOCATION: 'Objective location',
    QUEST: 'Quest',
    SETUP: 'Setup',
    SHIP_ENEMY: 'Ship enemy',
    SHIP_OBJECTIVE: 'Ship objective',
    TREACHERY: 'Treachery',
  }[type]
}

function subtypeLookup(subtype: string) {
  return {
    BOON: 'Boon',
    BURDEN: 'Burden',
  }[subtype]
}

function productTypeLookup(type: ProductType) {
  return {
    DELUXE_EXPANSION: 'Deluxe expansion',
    ADVENTURE_PACK: 'Adventure pack',
    SCENARIO_PACK: 'Scenario pack',
    CORE: 'Core',
    STARTER_DECK: 'Starter deck',
    HERO_EXPANSION: 'Hero expansion',
    CAMPAIGN_EXPANSION: 'Campaign expansion',
    STANDALONE_SCENARIO: 'Standalone scenario',
    SAGA_EXPANSION: 'Saga expansion',
    PREORDER_PROMOTION: 'Pre-order promotion',
    NIGHTMARE_EXPANSION: 'Nightmare expansion',
  }[type]
}

export {
  sphereLookup,
  sphereColors,
  sphereIconUrl,
  typeLookup,
  subtypeLookup,
  productTypeLookup,
}
