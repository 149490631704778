import {
  Box,
  HStack,
  Grid,
  GridItem,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'

import { ReactComponent as CGTLogo } from '../svgs/CGT_Logo.svg'

function Footer() {
  return (
    <Box
      bgGradient="linear(to-b, gray.50 0%, white 15%)"
      //color="gray.50"
      fontSize="xs"
      py={8}
      px={16}
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
    >
      <Grid templateColumns="repeat(3, 1fr)" gap={6} gridAutoFlow="dense">
        <GridItem colSpan={{ base: 3, xl: 1 }} colStart={{ base: 1, xl: 3 }}>
          <HStack>
            <CGTLogo height="40px" />
            <VStack alignItems="flex-start" spacing="0">
              <NavLink to={'/'}>
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  mb={-1}
                  _hover={{ textDecoration: 'underline' }}
                >
                  Lord of the Rings LCG
                </Text>
              </NavLink>
              <Text fontSize="sm">by CardGame.Tools</Text>
            </VStack>
          </HStack>
        </GridItem>
        <GridItem colSpan={{ base: 3, xl: 2 }}>
          <Text pb="2">
            CardGame.Tools is a project by Klutz (aka Kevin Belisle) but would
            not have been possible without the help and previous work by these
            fine people.
          </Text>
          <UnorderedList>
            <ListItem>
              The amazing and open-source{' '}
              <a href="http://hallofbeorn.com">Hall of Beorn</a> for the vast
              majority of card data and product card lists.
            </ListItem>
            <ListItem>
              @kirbsta2 on the COTR Discord for the card list of the repackaged
              Fellowship of the Ring saga.
            </ListItem>
            <ListItem>
              @Exaton on the COTR Discord for the card list of the repackaged
              Angmar Awakened expansions.
            </ListItem>
            <ListItem>
              Tana G from the Lord of the Rings LCG Players group on Facebook
              for the card list of the repackaged Dream-chaser Hero Expansion.
            </ListItem>
            <ListItem>
              u/LepcisMagna from Reddit's r/lotrlcg for the 600 DPI scans of
              almost all the original releases, PODs and Nigthmare decks.
            </ListItem>
            <ListItem>
              u/Guczini from Reddit's r/lotrlcg for the 600 DPI scans of the
              Dark of Mirkwood cards.
            </ListItem>
          </UnorderedList>
          {/* <Text py="2">Here are a few things I'm still looking for:</Text>
          <UnorderedList>
            <ListItem>
              600+ DPI scans of the new campaign cards from the newly repackaged
              Dream-chaser Campaign expansions.
            </ListItem>
          </UnorderedList> */}
        </GridItem>
        <GridItem colSpan={3}>
          <Text>
            This website is not produced, endorsed, supported, or affiliated
            with Fantasy Flight Games. The copyrightable portions of The Lord of
            the Rings: The Card Game and its expansions are © 2011 - 2023
            Fantasy Flight Publishing, Inc. The Lord of the Rings, and the
            characters, items, events and places therein are trademarks or
            registered trademarks of The Saul Zaentz Company d/b/a Middle-earth
            Enterprises and are used, under license, by Fantasy Flight Games.
            Living Card Game, LCG, LCG logo and Fantasy Flight Supply are
            trademarks and/or registered trademarks of Fantasy Flight
            Publishing, Inc. All Rights Reserved to their respective owners.
          </Text>
        </GridItem>
      </Grid>
    </Box>
  )
}

export default Footer
