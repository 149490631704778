import { AtSignIcon } from '@chakra-ui/icons'
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { AuthContext } from '../auth/AuthContext'
import { login, logout, userManager } from '../auth/AuthService'
import { UserContext } from '../user/UserContext'

const NavbarUserMenu = ({ mobile }: { mobile: boolean }) => {
  const { accessToken } = useContext(AuthContext)
  const username = useContext(UserContext)

  if (mobile) {
    return accessToken ? (
      <MenuGroup title={username ?? 'Choose a username!'}>
        <NavLink to="/profile">
          <MenuItem>Manage profile</MenuItem>
        </NavLink>
        <NavLink to="/collection">
          <MenuItem>Manage collection</MenuItem>
        </NavLink>
        <MenuItem onClick={() => userManager.signinSilent()}>
          Refresh token
        </MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </MenuGroup>
    ) : (
      <MenuItem
        onClick={() => {
          login()
        }}
      >
        Login / Register
      </MenuItem>
    )
  }

  return accessToken ? (
    <Menu isLazy>
      <MenuButton
        as={IconButton}
        aria-label="Menu"
        icon={<AtSignIcon />}
        variant="solid"
        colorScheme="teal"
        bg="teal.300"
      />
      <MenuList zIndex="1000">
        <MenuGroup title={username ?? 'Choose a username!'}>
          <NavLink to="/profile">
            <MenuItem>Manage profile</MenuItem>
          </NavLink>
          <NavLink to="/collection">
            <MenuItem>Manage collection</MenuItem>
          </NavLink>
          <MenuDivider />
          <MenuItem onClick={logout}>Logout</MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  ) : (
    <Button
      colorScheme="teal"
      bg="teal.400"
      onClick={() => {
        login()
      }}
    >
      Login / Register
    </Button>
  )
}

export default NavbarUserMenu
