import { gql } from '@apollo/client'

const updateUserProfile = gql`
  mutation ($username: String!) {
    updateUserProfile(username: $username) {
      id
      profile {
        username
      }
    }
  }
`

const updateUserProduct = gql`
  mutation ($productCode: String!, $quantity: Int!) {
    updateUserProduct(productCode: $productCode, quantity: $quantity) {
      id
      products {
        quantity
        productCode
      }
    }
  }
`

const updateUserProducts = gql`
  mutation ($userProducts: [ProductWithQuantityInput!]!) {
    updateUserProducts(userProducts: $userProducts) {
      id
      products {
        quantity
        productCode
      }
    }
  }
`

export { updateUserProduct, updateUserProducts, updateUserProfile }
