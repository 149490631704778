import {
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  HStack,
  Image,
  Tag,
  Text,
  useToken,
  VStack,
} from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'

import { sphereColors } from '../helpers/enumLookups'
import { formatCardText, formatNumber } from '../helpers/textReplacements'
import { ReactComponent as Threat } from '../svgs/Threat.svg'
import type { CardSideType } from '../Types'
import SphereTag from './SphereTag'
import StatTag from './StatTag'
import TypeTag from './TypeTag'

function LotrCardSide({
  side,
  slug,
  leftStretch,
}: {
  side: CardSideType
  slug: string
  leftStretch: string
}) {
  const [space4] = useToken('space', ['4'])

  return (
    <Card
      p={space4}
      bgGradient={
        side.sphere
          ? `linear(to-r, ${
              sphereColors(side.sphere ?? 'NEUTRAL').bg
            }.50, white)`
          : `linear(120deg, red.50 20%, yellow.50, white 75%)`
      }
      ml={`-${leftStretch}`}
      pl={`calc(${leftStretch} + ${space4})`}
    >
      <CardBody p={0} minHeight="215px">
        <HStack spacing={2} mb={2}>
          {side.stage != undefined && (
            <Tag
              fontFamily="vafthrudnir"
              fontSize={20}
              py={0.5}
              px={0}
              justifyContent="center"
              bg="gray.800"
              color="blue.200"
              borderRadius="12px"
              minWidth={10}
            >
              <span
                style={{
                  position: 'relative',
                  top: '3px',
                }}
              >
                {side.stage}
              </span>
            </Tag>
          )}
          {side.isUnique && (
            <Image
              src="/icons/Unique.svg"
              boxSize="20px"
              position="relative"
              top="2px"
            />
          )}
          <Heading
            size="md"
            fontFamily="vafthrudnir"
            fontSize="28px"
            fontWeight="normal"
            position="relative"
            top="6px"
            sx={{ fontVariant: 'small-caps' }}
            flexGrow={1}
          >
            <NavLink to={`/card/${slug}`}>{side.title}</NavLink>
          </Heading>
        </HStack>
        <Flex justify="space-between" mb={2} flexWrap="wrap" rowGap={2}>
          <HStack spacing={2} flexGrow={1}>
            {side.sphere && <SphereTag sphere={side.sphere} />}
            {/* eslint-disable-next-line eqeqeq */}
            {side.resourceCost != undefined && (
              <Tag bg="white" borderColor="gray.100" borderWidth={0.5}>
                <Text mr="1">Cost:</Text>
                <Text fontFamily="vafthrudnir" position="relative" top="3px">
                  {formatNumber(side.resourceCost)}
                </Text>
              </Tag>
            )}
            {/* eslint-disable-next-line eqeqeq */}
            {(side.threatCost != undefined ||
              side.engagementCost != undefined ||
              side.questPoints != undefined) && (
              <Tag bg="white" borderColor="gray.100" borderWidth={0.5}>
                <Text
                  fontFamily="vafthrudnir"
                  position="relative"
                  top="4px"
                  mr={1}
                >
                  {side.threatCost != undefined &&
                    formatNumber(side.threatCost)}
                  {side.engagementCost != undefined &&
                    formatNumber(side.engagementCost)}
                  {side.questPoints != undefined &&
                    formatNumber(side.questPoints)}
                </Text>
                <Threat width="16px" height="16px" />
              </Tag>
            )}
            {side.type ? (
              <TypeTag type={side.type} subtype={side.subtype} />
            ) : (
              <></>
            )}
          </HStack>
          <HStack spacing={2} flexGrow={1} justifyContent="flex-end">
            <StatTag
              threatStrength={side.threatStrength}
              willpower={side.willpower}
              attack={side.attack}
              defense={side.defense}
              hitPoints={side.hitPoints}
            />
          </HStack>
        </Flex>
        <VStack alignItems="start" spacing={1}>
          {(side.traits?.length ?? 0) + (side.keywords?.length ?? 0) > 0 && (
            <Text
              fontSize="md"
              fontWeight={600}
              fontStyle="italic"
              fontFamily="EB Garamond"
            >
              {[side.traits?.join(' '), side.keywords?.join(' ')]
                .filter((x) => x !== '')
                .join(' - ')}
            </Text>
          )}
          {(side.maxPerDeck ?? 0) > 0 && (
            <Text>Limit {side.maxPerDeck} per deck.</Text>
          )}
          <Text
            fontSize="md"
            fontFamily="EB Garamond"
            lineHeight={1.4}
            whiteSpace="pre-wrap"
          >
            {formatCardText(side.text)}
          </Text>
          {side.shadowEffect && (
            <Text
              fontSize="md"
              fontStyle="italic"
              fontFamily="EB Garamond"
              lineHeight={1.4}
              whiteSpace="pre-wrap"
            >
              <b>Shadow:</b> {formatCardText(side.shadowEffect)}
            </Text>
          )}
          {side.flavorText && (
            <Text
              fontSize="sm"
              fontStyle="italic"
              fontFamily="EB Garamond"
              lineHeight={1.4}
              whiteSpace="pre-wrap"
            >
              {side.flavorText}
            </Text>
          )}
          {side.victoryPoints && (
            <Text
              fontSize="md"
              fontFamily="vafthrudnir"
              lineHeight={1.4}
              alignSelf="flex-end"
            >
              VICTORY {side.victoryPoints}
            </Text>
          )}
        </VStack>
      </CardBody>
    </Card>
  )
}

export default LotrCardSide
