import {
  Alert,
  AlertIcon,
  Card,
  CardHeader,
  CardBody,
  Divider,
  Heading,
  HStack,
  Image,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react'

import { productTypeLookup } from '../../helpers/enumLookups'
import type { ProductWithCardVersions } from '../../Types'
import ProductCardChecklist from './ProductCardChecklist'
import ProductCardImageGrid from './ProductCardImageGrid'
import ProductCardList from './ProductCardList'

function HelpRequestedAlert({ productCode }: { productCode: string }) {
  return [''].includes(productCode) ? (
    <Alert
      status="warning"
      borderRadius={8}
      width="100%"
      py={2}
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
    >
      <AlertIcon />
      <Text>
        I don't have complete card lising data for this product.
        <br />
        If you own the product and would like to contribute, please let me know!
      </Text>
    </Alert>
  ) : (
    <></>
  )
}

function Product({
  product,
  viewMode,
}: {
  product: ProductWithCardVersions
  viewMode: 'checklist' | 'images' | 'cards'
}) {
  return (
    <Card
      mt={{ base: 4, md: 8 }}
      ml={{ base: 4, md: 8 }}
      mr={{ base: 4, md: 8 }}
      mb={{ base: 8, md: 12 }}
      width="auto"
      sx={{
        pageBreakInside: 'avoid',
        '@media print': {
          boxShadow: 'none',
          padding: 0,
          margin: '0 0 0.25in 0',
        },
      }}
    >
      <CardHeader
        display="flex"
        flexDirection="column"
        alignItems="start"
        gap={2}
        borderBottomColor="gray.200"
        borderBottomWidth="1px"
        sx={{
          '@media print': {
            paddingTop: 0,
            paddingBottom: 0,
            gap: '0',
          },
        }}
      >
        <Heading fontFamily="ringbearer">
          <Image
            width={10}
            mb={-1}
            pr={1}
            src={`/icons/expansion symbols/${product?.expansionSymbol}`}
            display="inline"
          />
          {product?.name}
        </Heading>
        <HStack flexWrap="wrap">
          <Tag size="md">
            <TagLabel>{productTypeLookup(product.type)}</TagLabel>
          </Tag>
          <Tag size="md">
            <TagLabel>
              Aka: {product.code}, {product.abbreviation}
            </TagLabel>
          </Tag>
          {product.cycle && (
            <Tag size="md">
              <TagLabel>Cycle: {product.cycle}</TagLabel>
            </Tag>
          )}
          <Tag size="md">
            <TagLabel>
              First released: {product.firstReleased.toString()}
            </TagLabel>
          </Tag>
          <Tag size="md">
            <TagLabel>
              {product.isRepackage ? 'Repackaged release' : 'Original release'}
            </TagLabel>
          </Tag>
        </HStack>
        <HelpRequestedAlert productCode={product.code} />
      </CardHeader>
      <CardBody>
        {
          {
            checklist: <ProductCardChecklist cards={product.cards} />,
            images: <ProductCardImageGrid cards={product.cards} />,
            cards: <ProductCardList cards={product.cards} />,
          }[viewMode]
        }
      </CardBody>
    </Card>
  )
}

export default Product
