import { useQuery } from '@apollo/client'
import {
  Button,
  Card,
  Flex,
  Select,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useState, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import useLocalStorage from '../../hooks/useLocalStorage'
import { productDetailsQuery } from '../../Queries'
import type { ProductWithCardVersions } from '../../Types'
import Product from './Product'

function ProductList() {
  const [searchParams, setSearchParams] = useSearchParams()

  const activeProductCodes = (searchParams
    .get('codes')
    ?.split('+')
    .filter((x) => x != '') ?? []) as string[]

  const setActiveProductCodes = (productCodes: string[]) => {
    setSearchParams({ codes: productCodes.join('+') })
  }

  const [viewMode, setViewMode] = useLocalStorage(
    'productListVewMode',
    'checklist' as 'cards' | 'checklist' | 'images'
  )

  const {
    loading,
    error,
    data: activeProducts,
  } = useQuery(productDetailsQuery, {
    variables: {
      productCodes: activeProductCodes,
    },
  })

  if (loading)
    return (
      <VStack mt="20" spacing="4">
        <Spinner size="xl" color="white" />
        <Text color="white">Loading...</Text>
      </VStack>
    )

  if (error) return <>Error! ${error.message}</>

  return (
    <>
      {activeProducts.products.nodes.length > 0 && (
        <Card
          mt={{ base: 4, md: 8 }}
          ml={{ base: 4, md: 8 }}
          mr={{ base: 4, md: 8 }}
          p="2"
          bg="gray.500"
          sx={{
            '@media print': {
              display: 'none',
            },
          }}
        >
          <Flex wrap="wrap" justifyContent="space-between" gap="2">
            <Select
              size="sm"
              variant="filled"
              flexGrow="0"
              width="auto"
              value={viewMode}
              onChange={(e) =>
                setViewMode(e.target.value as 'cards' | 'checklist' | 'images')
              }
            >
              <option value="checklist">View as card list</option>
              <option value="cards">View as card details</option>
              <option value="images">View as card images</option>
            </Select>

            {viewMode === 'checklist' && (
              <Button
                onClick={window.print}
                flexGrow={0}
                variant="solid"
                size="sm"
                colorScheme="gray"
              >
                Print as checklist
              </Button>
            )}
          </Flex>
        </Card>
      )}
      {activeProducts.products.nodes.map(
        (activeProduct: ProductWithCardVersions) => (
          <Product product={activeProduct} viewMode={viewMode} />
        )
      )}
    </>
  )
}

export default ProductList
