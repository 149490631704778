import {
  Log,
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from 'oidc-client-ts'

import { trackEvent } from '../matomo'
import { AuthContextRef } from './AuthContext'

const settings: UserManagerSettings = {
  authority: 'https://auth.cardgame.tools/',
  client_id: '60eaf929-de7d-483a-a65f-ce26e3840355',
  client_secret: 'csWxx9Q4FFfgC2cI3MiQY2nsP2JEgPGCakUzQrViSvw',
  redirect_uri: 'https://lotr.cardgame.tools/oauth2callback',
  silent_redirect_uri: 'https://lotr.cardgame.tools/silent-renew.html',
  post_logout_redirect_uri: 'https://lotr.cardgame.tools/',
  response_type: 'code',
  scope: 'offline_access openid',
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
}

const userManager = new UserManager(settings)

userManager.events.addAccessTokenExpired(() => {
  userManager.signinSilent().catch(() => {
    userManager.removeUser()
    AuthContextRef.current?.setAccessToken(null)
  })
})

userManager.events.addUserLoaded((user) => {
  AuthContextRef.current?.setAccessToken(user.access_token)
})

//userManager.events.addSilentRenewError((error) =>
//console.log({ silentRenewError: error })
//)

//userManager.events.addUserSignedOut(() => console.log('UserSignedOut'))

//userManager.events.addUserUnloaded(() => console.log('UserUnloaded'))

const getUser = () => {
  return userManager.getUser()
}

const login = () => {
  trackEvent('auth', 'login', null, null)
  return userManager.signinRedirect()
}

const logout = () => {
  trackEvent('auth', 'logout', null, null)
  return userManager.signoutRedirect()
}

const signinCallback = () => {
  trackEvent('auth', 'signedIn', null, null)
  return userManager.signinRedirectCallback()
}

export { getUser, login, logout, signinCallback, userManager }
