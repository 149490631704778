import { useQuery } from '@apollo/client'
import type { DocumentNode, QueryResult } from '@apollo/client'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertIcon,
  Box,
  Card,
  CardHeader,
  CardBody,
  Checkbox,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Input,
  SimpleGrid,
  Tag,
  TagLabel,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { ReactElement, useState, useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { AuthContext } from '../../auth/AuthContext'
import { login, logout, userManager } from '../../auth/AuthService'
import CardFilter from '../../components/CardFilter'
import useDebounce from '../../hooks/useDebounce'
import { UserContext } from '../../user/UserContext'

export default function ({
  includedCards,
  setIncludedCards,
  excludedCards,
  setExcludedCards,
  limitToUserCollection,
  setLimitToUserCollection,
}: {
  includedCards: { slug: string; title: string }[]
  setIncludedCards: React.Dispatch<
    React.SetStateAction<
      {
        slug: string
        title: string
      }[]
    >
  >
  excludedCards: { slug: string; title: string }[]
  setExcludedCards: React.Dispatch<
    React.SetStateAction<
      {
        slug: string
        title: string
      }[]
    >
  >
  limitToUserCollection: boolean
  setLimitToUserCollection: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { accessToken } = useContext(AuthContext)
  const username = useContext(UserContext)

  const [expanded, toggle] = useState(true)

  const addIncludedCard = (card: { slug: string; title: string }) =>
    setIncludedCards((i) => [...i, card])
  const removeIncludedSlug = (slug: string) =>
    setIncludedCards((i) => i.filter((s) => s.slug !== slug))
  const clearIncludedSlugs = () => setIncludedCards([])

  const addExcludedCard = (card: { slug: string; title: string }) =>
    setExcludedCards((i) => [...i, card])
  const removeExcludedSlug = (slug: string) =>
    setExcludedCards((i) => i.filter((s) => s.slug !== slug))
  const clearExcludedSlugs = () => setExcludedCards([])

  return (
    <Box
      color="white"
      bgGradient="linear(to-r, gray.800, gray.900)"
      width={{ base: '100%' /*, lg: '380px'*/ }}
      flexShrink="0"
      px={8}
      py={4}
      display="flex"
      flexDirection="column"
      min-height="100%"
      overflowX="hidden"
      overflowY="auto"
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
    >
      <HStack
        width="100%"
        justifyContent="space-between"
        alignItems="baseline"
        cursor="pointer"
        onClick={() => toggle(!expanded)}
      >
        <Text fontSize="lg" fontWeight="bold">
          Filter decks
        </Text>
        {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </HStack>
      {expanded && (
        <VStack alignItems="flex-start" mt={4}>
          <Flex
            alignItems="baseline"
            justifyContent="space-between"
            width="100%"
          >
            <Checkbox
              disabled={!accessToken}
              isChecked={limitToUserCollection}
              onChange={() => setLimitToUserCollection(!limitToUserCollection)}
            >
              {accessToken
                ? 'Only decks I can build with my collection'
                : 'Login or signup to filter by your collection.'}
            </Checkbox>
            <Text fontSize="xs" color="blue.500" decoration="underline">
              <NavLink to="/collection">Manage your collection here.</NavLink>
            </Text>
          </Flex>
          <CardFilter
            title="Decks that include all of these cards"
            cards={includedCards}
            addCard={addIncludedCard}
            removeSlug={removeIncludedSlug}
            clearSlugs={clearIncludedSlugs}
          />
          <CardFilter
            title="Decks that do not include any of these cards"
            cards={excludedCards}
            addCard={addExcludedCard}
            removeSlug={removeExcludedSlug}
            clearSlugs={clearExcludedSlugs}
          />
        </VStack>
      )}
    </Box>
  )
}
