import { HStack, Image, Text } from '@chakra-ui/react'
import StatIconUrl from '../helpers/statIconUrl'
import { formatNumber } from '../helpers/textReplacements'

function StatTag({
  threatStrength,
  willpower,
  attack,
  defense,
  hitPoints,
  fontFamily = 'vafthrudnir',
  textOffset = '3px',
  iconSize = '16px',
}: {
  threatStrength?: number
  willpower?: number
  attack?: number
  defense?: number
  hitPoints?: number
  fontFamily?: string
  textOffset?: string
  iconSize?: string
}) {
  return (
    <>
      <HStack spacing={2}>
        {threatStrength != undefined && (
          <HStack spacing={1}>
            <Text fontFamily={fontFamily} position="relative" top={textOffset}>
              {formatNumber(threatStrength)}
            </Text>
            <Image src={StatIconUrl('THREAT')} boxSize={iconSize} />
          </HStack>
        )}
        {willpower != undefined && (
          <HStack spacing={1}>
            <Text fontFamily={fontFamily} position="relative" top={textOffset}>
              {formatNumber(willpower)}
            </Text>
            <Image src={StatIconUrl('WILLPOWER')} boxSize={iconSize} />
          </HStack>
        )}
        {attack != undefined && (
          <HStack spacing={1}>
            <Text fontFamily={fontFamily} position="relative" top={textOffset}>
              {formatNumber(attack)}
            </Text>
            <Image src={StatIconUrl('ATTACK')} boxSize={iconSize} />
          </HStack>
        )}
        {defense != undefined && (
          <HStack spacing={1}>
            <Text fontFamily={fontFamily} position="relative" top={textOffset}>
              {formatNumber(defense)}
            </Text>
            <Image src={StatIconUrl('DEFENSE')} boxSize={iconSize} />
          </HStack>
        )}
        {hitPoints != undefined && (
          <HStack spacing={1}>
            <Text fontFamily={fontFamily} position="relative" top={textOffset}>
              {formatNumber(hitPoints)}
            </Text>
            <Image src={StatIconUrl('HITPOINTS')} boxSize={iconSize} />
          </HStack>
        )}
      </HStack>
    </>
  )
}

export default StatTag
