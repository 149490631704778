import { Outlet } from 'react-router-dom'

import Footer from './components/Footer'
import Navbar from './components/Navbar'
import { useMatomoPageView } from './matomo'

const App = () => {
  useMatomoPageView()

  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  )
}

export default App
