import { useQuery } from '@apollo/client'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertIcon,
  Box,
  Card,
  CardHeader,
  CardBody,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Tag,
  TagLabel,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { ReactElement, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import SphereTag from '../../components/SphereTag'
import StatTag from '../../components/StatTag'
import TypeTag from '../../components/TypeTag'
import { formatCardText, formatNumber } from '../../helpers/textReplacements'
import { deckSearchQuery, randomDecksQuery } from '../../Queries'
import { ReactComponent as Threat } from '../../svgs/Threat.svg'
import { Deck } from '../../Types'
import DeckBox from './deckBox'
import SearchCriteria from './searchCriteria'

function Layout() {
  const [includedCards, setIncludedCards] = useState(
    [] as { slug: string; title: string }[]
  )

  const [excludedCards, setExcludedCards] = useState(
    [] as { slug: string; title: string }[]
  )

  const [limitToUserCollection, setLimitToUserCollection] = useState(false)

  const randomDecksQueryData = useQuery(randomDecksQuery)

  const showRandomDecks =
    !limitToUserCollection && !includedCards.length && !excludedCards.length

  const deckSearchQueryData = useQuery(deckSearchQuery, {
    variables: {
      includedCardSlugs: includedCards.map((c) => c.slug),
      excludedCardSlugs: excludedCards.map((c) => c.slug),
      limitToUserCollection: limitToUserCollection,
      first: 10,
    },
    skip: showRandomDecks,
  })

  const randomDeckshResults: Deck[] =
    randomDecksQueryData?.data?.decks?.nodes ?? []
  const deckSearchResults: Deck[] =
    deckSearchQueryData?.data?.deckSearch?.nodes ?? []

  console.log(deckSearchResults)

  return (
    <Box
      bgGradient="linear(to-tl, brand.900, brand.700)"
      minHeight="calc(100vh - 60px)"
    >
      <SearchCriteria
        includedCards={includedCards}
        setIncludedCards={setIncludedCards}
        excludedCards={excludedCards}
        setExcludedCards={setExcludedCards}
        limitToUserCollection={limitToUserCollection}
        setLimitToUserCollection={setLimitToUserCollection}
      />

      <VStack spacing="8" alignItems="stretch" minHeight="100vh" p={8}>
        <Card
          key="topcard"
          p="2"
          bg="gray.500"
          sx={{
            '@media print': {
              display: 'none',
            },
          }}
        >
          <Flex
            wrap="wrap"
            justifyContent="flex-start"
            alignItems="center"
            gap="4"
          >
            <Text px={2} width={{ base: '100%' /*md: 'auto' */ }} color="white">
              {showRandomDecks
                ? 'A few random decks to get you inspired...'
                : `Found ${deckSearchQueryData?.data?.deckSearch?.totalCount} decks that match your filters, here are ${deckSearchResults.length} of them!`}
            </Text>
          </Flex>
        </Card>

        <SimpleGrid minChildWidth="540px" spacing="40px">
          {(showRandomDecks ? randomDeckshResults : deckSearchResults).map(
            (deck) => (
              <DeckBox deck={deck} />
            )
          )}
        </SimpleGrid>
      </VStack>
    </Box>
  )
}

export default Layout
