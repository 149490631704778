import { Box, Image, SimpleGrid } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'

import type { CardWithVersions } from '../../Types'

function ThinSpace() {
  return <span style={{ fontSize: '0.2em' }}> </span>
}

function CardGridImages({ card }: { card: CardWithVersions }) {
  const frontOrientation = card.front.orientation
  const backOrientation = card.back?.orientation ?? card.front.orientation
  const horizontalCard =
    frontOrientation == 'HORIZONTAL' && backOrientation == 'HORIZONTAL'

  const backImageUrl = card.versions[0].backImageUrl
    ? card.versions[0].backImageUrl.startsWith('http')
      ? card.versions[0].backImageUrl
      : `https://images.cardgame.tools/lotr/sm/${card.versions[0].backImageUrl}`
    : [
        'QUEST',
        'ENEMY',
        'SHIP_ENEMY',
        'LOCATION',
        'TREACHERY',
        'ENCOUNTER_SIDE_QUEST',
        'CAVE',
        'OBJECTIVE',
        'OBJECTIVE_HERO',
        'OBJECTIVE_ALLY',
        'OBJECTIVE_LOCATION',
        'SHIP_OBJECTIVE',
      ].includes(card.front.type) || card.front.keywords?.includes('Encounter.')
    ? {
        HORIZONTAL: '/images/HorizontalEncounterBack.jpg',
        VERTICAL: '/images/VerticalEncounterBack.jpg',
      }[frontOrientation]
    : {
        HORIZONTAL: '/images/HorizontalPlayerBack.jpg',
        VERTICAL: '/images/VerticalPlayerBack.jpg',
      }[frontOrientation]

  const images = [
    <Image
      key="back"
      src={backImageUrl}
      maxHeight="250px"
      maxWidth="240px"
      borderRadius={14}
      boxShadow="0px 3px 8px 0px rgba(45,55,72,0.75), 0px 0px 3px 2px rgba(255,255,255,0.65)"
      transform="rotate(-2deg)"
      fallbackSrc={
        {
          HORIZONTAL: '/images/HorizontalPlaceholder.jpg',
          VERTICAL: '/images/VerticalPlaceholder.jpg',
        }[backOrientation]
      }
      position="absolute"
      top={
        {
          HORIZONTAL: '80px',
          VERTICAL: '0',
        }[backOrientation]
      }
    />,

    <Image
      key="front"
      src={
        card.versions[0].frontImageUrl.startsWith('http')
          ? card.versions[0].frontImageUrl
          : `https://images.cardgame.tools/lotr/sm/${card.versions[0].frontImageUrl}`
      }
      maxHeight="250px"
      maxWidth="240px"
      borderRadius={14}
      boxShadow="0px 3px 8px 0px rgba(45,55,72,0.75), 0px 0px 3px 2px rgba(255,255,255,0.65)"
      transform="rotate(2deg)"
      fallbackSrc={
        {
          HORIZONTAL: '/images/HorizontalPlaceholder.jpg',
          VERTICAL: '/images/VerticalPlaceholder.jpg',
        }[card.front.orientation]
      }
      position="absolute"
      right="0px"
      top={
        {
          HORIZONTAL: '0',
          VERTICAL: '0',
        }[frontOrientation]
      }
    />,
  ]

  return <>{images}</>
}

function CardImageGrid({ cards }: { cards: CardWithVersions[] }) {
  return (
    <SimpleGrid
      key="grid"
      autoFlow="row"
      rowGap="2"
      columnGap="4"
      alignItems="center"
      minChildWidth="240px"
    >
      {cards.slice().map((card, index) => (
        <NavLink key={card.slug} to={`/card/${card.slug}`}>
          <Box position="relative" height="280px">
            <CardGridImages card={card} />
          </Box>
        </NavLink>
      ))}
    </SimpleGrid>
  )
}

export default CardImageGrid
