// https://github.com/shamaz332/matomo-react-tracker

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

declare global {
  interface Window {
    _paq: any
  }
}

export function initMatomo(matomoURL: string, siteID: string) {
  window._paq = window._paq || []

  window._paq.push(['setTrackerUrl', `${matomoURL}/sherlock.php`])
  window._paq.push(['setSiteId', siteID])

  const scriptElement = document.createElement('script')
  scriptElement.type = 'text/javascript'
  scriptElement.async = true
  scriptElement.defer = true
  scriptElement.src = `/sherlock.js` //`${matomoURL}/sherlock.js`

  document.getElementsByTagName('head')[0].appendChild(scriptElement)
}

export function trackPageView(customUrl?: string) {
  if (window._paq) {
    window._paq.push(['setCustomUrl', customUrl || window.location.href])
    window._paq.push(['trackPageView'])
  }
}

export function trackEvent(
  category: string,
  action: string,
  name: string | null,
  value: string | null
) {
  if (window._paq) {
    window._paq.push(['trackEvent', category, action, name, value])
  }
}

export function setUserId(userId: string) {
  window._paq.push(['setUserId', userId])
}

export function resetUserId() {
  window._paq.push(['resetUserId'])
}

// Hooks
export function useMatomoPageView() {
  const { pathname } = useLocation()

  useEffect(() => {
    trackPageView()
  }, [pathname])
}
