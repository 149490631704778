import { Box, VStack, Spinner, Text } from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import { AuthContext } from './AuthContext'
import { signinCallback } from './AuthService'

const SigninCallback = () => {
  const { accessToken, setAccessToken } = useContext(AuthContext)
  const [output, setOutput] = useState(
    <Box
      p={{ base: 4, md: 8 }}
      bgGradient="linear(to-tl, brand.900, brand.700)"
    >
      <VStack my="40" spacing="4">
        <Spinner size="xl" color="white" />
        <Text color="white">Signing in...</Text>
      </VStack>
    </Box>
  )

  useEffect(() => {
    ;(async () => {
      try {
        const user = await signinCallback()
        setAccessToken(user?.access_token as string)
      } catch (e) {
        console.error(e)
        setAccessToken(null)
      } finally {
        setOutput(<Navigate to="/" replace={true} />)
      }
    })()
  }, [])

  return output
}

export default SigninCallback
