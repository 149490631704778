import { gql } from '@apollo/client'

const cardBySlugQuery = gql`
  query ($slug: String!) {
    card: cardBySlug(slug: $slug) {
      slug
      front {
        slug
        type
        subtype
        sphere
        title
        text
        flavorText
        threatCost
        resourceCost
        willpower
        attack
        defense
        hitPoints
        isUnique
        victoryPoints
        questPoints
        traits
        keywords
        maxPerDeck
        direction
        engagementCost
        orientation
        stage
        questPoints
        shadowEffect
        threatStrength
      }
      back {
        slug
        type
        subtype
        sphere
        title
        text
        flavorText
        threatCost
        resourceCost
        willpower
        attack
        defense
        hitPoints
        isUnique
        victoryPoints
        questPoints
        traits
        keywords
        maxPerDeck
        direction
        engagementCost
        orientation
        stage
        questPoints
        shadowEffect
        threatStrength
      }
      versions: productCards {
        product {
          code
          name
          abbreviation
          expansionSymbol
          type
        }
        number
        quantity
        frontImageUrl
        backImageUrl
      }
    }
  }
`

const randomCardsQuery = gql`
  query {
    cards: randomCards(first: 20) {
      nodes {
        slug
        front {
          slug
          type
          subtype
          sphere
          title
          text
          flavorText
          threatCost
          resourceCost
          willpower
          attack
          defense
          hitPoints
          isUnique
          victoryPoints
          questPoints
          traits
          keywords
          maxPerDeck
          direction
          engagementCost
          orientation
          stage
          questPoints
          shadowEffect
          threatStrength
        }
        back {
          slug
          type
          subtype
          sphere
          title
          text
          flavorText
          threatCost
          resourceCost
          willpower
          attack
          defense
          hitPoints
          isUnique
          victoryPoints
          questPoints
          traits
          keywords
          maxPerDeck
          direction
          engagementCost
          orientation
          stage
          questPoints
          shadowEffect
          threatStrength
        }
        versions: productCards {
          product {
            code
            name
            abbreviation
            expansionSymbol
            type
          }
          number
          quantity
          frontImageUrl
          backImageUrl
        }
      }
    }
  }
`

const cardsByTitleQuery = gql`
  query ($title: String, $first: Int, $after: String) {
    cards: advancedSearch(title: $title, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      nodes {
        slug
        front {
          slug
          type
          subtype
          sphere
          title
          text
          flavorText
          threatCost
          resourceCost
          willpower
          attack
          defense
          hitPoints
          isUnique
          victoryPoints
          questPoints
          traits
          keywords
          maxPerDeck
          direction
          engagementCost
          orientation
          stage
          questPoints
          shadowEffect
          threatStrength
        }
        back {
          slug
          type
          subtype
          sphere
          title
          text
          flavorText
          threatCost
          resourceCost
          willpower
          attack
          defense
          hitPoints
          isUnique
          victoryPoints
          questPoints
          traits
          keywords
          maxPerDeck
          direction
          engagementCost
          orientation
          stage
          questPoints
          shadowEffect
          threatStrength
        }
        versions: productCards {
          product {
            code
            name
            abbreviation
            type
          }
          number
          quantity
          frontImageUrl
          backImageUrl
        }
      }
    }
  }
`

const cardNamesByTitleQuery = gql`
  query ($title: String, $first: Int, $after: String) {
    cards: advancedSearch(
      title: $title
      cardTypes: [
        HERO
        ALLY
        ATTACHMENT
        EVENT
        TREASURE
        PLAYER_SIDE_QUEST
        CONTRACT
      ]
      first: $first
      after: $after
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      nodes {
        slug
        front {
          title
        }
      }
    }
  }
`

const cardSearchQuery = gql`
  query (
    $title: String
    $spheres: [Sphere!]
    $cardTypes: [CardType!]
    $cardSubtypes: [CardSubtype!]
    $traits: [String!]
    $willpower: RangeInput
    $attack: RangeInput
    $defense: RangeInput
    $hitPoints: RangeInput
    $resourceCost: RangeInput
    $threatCost: RangeInput
    $productCodes: [String!]
    $first: Int
    $after: String
  ) {
    cards: advancedSearch(
      title: $title
      spheres: $spheres
      cardTypes: $cardTypes
      cardSubtypes: $cardSubtypes
      traits: $traits
      willpower: $willpower
      attack: $attack
      defense: $defense
      hitPoints: $hitPoints
      resourceCost: $resourceCost
      threatCost: $threatCost
      productCodes: $productCodes
      first: $first
      after: $after
      order: [{ slug: ASC }]
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      nodes {
        slug
        front {
          slug
          type
          subtype
          sphere
          title
          text
          flavorText
          threatCost
          resourceCost
          willpower
          attack
          defense
          hitPoints
          isUnique
          victoryPoints
          questPoints
          traits
          keywords
          maxPerDeck
          direction
          engagementCost
          orientation
          stage
          questPoints
          shadowEffect
          threatStrength
        }
        back {
          slug
          type
          subtype
          sphere
          title
          text
          flavorText
          threatCost
          resourceCost
          willpower
          attack
          defense
          hitPoints
          isUnique
          victoryPoints
          questPoints
          traits
          keywords
          maxPerDeck
          direction
          engagementCost
          orientation
          stage
          questPoints
          shadowEffect
          threatStrength
        }
        versions: productCards {
          product {
            code
            name
            abbreviation
            type
          }
          number
          quantity
          frontImageUrl
          backImageUrl
        }
      }
    }
  }
`

const allProductsQuery = gql`
  query {
    productList {
      abbreviation
      code
      name
      firstReleased
      isRepackage
      category
      cycle
      expansionSymbol
      type
    }
  }
`

// const allProductsQuery = gql`
//   query {
//     products(first: 1000) {
//       pageInfo {
//         hasNextPage
//         endCursor
//       }
//       totalCount
//       nodes {
//         abbreviation
//         code
//         name
//         firstReleased
//         isRepackage
//         category
//         cycle
//         expansionSymbol
//         type
//       }
//     }
//   }
// `

const productDetailsQuery = gql`
  query ($productCodes: [String!]) {
    products(productCodes: $productCodes, first: 10) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      nodes {
        abbreviation
        code
        name
        firstReleased
        isRepackage
        category
        cycle
        expansionSymbol
        type
        cards: productCards {
          quantity
          number
          backNumber
          frontImageUrl
          backImageUrl
          card {
            slug
            front {
              slug
              type
              subtype
              sphere
              title
              text
              flavorText
              threatCost
              resourceCost
              willpower
              attack
              defense
              hitPoints
              isUnique
              victoryPoints
              questPoints
              traits
              keywords
              maxPerDeck
              direction
              engagementCost
              orientation
              stage
              questPoints
              shadowEffect
              threatStrength
            }
            back {
              slug
              type
              subtype
              sphere
              title
              text
              flavorText
              threatCost
              resourceCost
              willpower
              attack
              defense
              hitPoints
              isUnique
              victoryPoints
              questPoints
              traits
              keywords
              maxPerDeck
              direction
              engagementCost
              orientation
              stage
              questPoints
              shadowEffect
              threatStrength
            }
            versions: productCards {
              product {
                code
                name
                abbreviation
                expansionSymbol
                type
              }
              number
              quantity
              frontImageUrl
              backImageUrl
            }
          }
        }
      }
    }
  }
`

// productCodes: $productCodes,
const deckSearchQuery = gql`
  query (
    $includedCardSlugs: [String!]!
    $excludedCardSlugs: [String!]!
    $limitToUserCollection: Boolean!
  ) {
    deckSearch(
      includedCardSlugs: $includedCardSlugs
      excludedCardSlugs: $excludedCardSlugs
      limitToUserCollection: $limitToUserCollection
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      nodes {
        id
        name
        favorites
        votes
        cards: ringsDbDeckCards {
          quantity
          card {
            slug
            front {
              slug
              type
              subtype
              sphere
              title
              text
              flavorText
              threatCost
              resourceCost
              willpower
              attack
              defense
              hitPoints
              isUnique
              victoryPoints
              questPoints
              traits
              keywords
              maxPerDeck
              direction
              engagementCost
              orientation
              stage
              questPoints
              shadowEffect
              threatStrength
            }
            back {
              slug
              type
              subtype
              sphere
              title
              text
              flavorText
              threatCost
              resourceCost
              willpower
              attack
              defense
              hitPoints
              isUnique
              victoryPoints
              questPoints
              traits
              keywords
              maxPerDeck
              direction
              engagementCost
              orientation
              stage
              questPoints
              shadowEffect
              threatStrength
            }
            versions: productCards {
              product {
                code
                name
                abbreviation
                expansionSymbol
                type
              }
              number
              quantity
              frontImageUrl
              backImageUrl
            }
          }
        }
      }
    }
  }
`

const randomDecksQuery = gql`
  query {
    decks: randomDecks(first: 20) {
      nodes {
        id
        name
        favorites
        votes
        cards: ringsDbDeckCards {
          quantity
          card {
            slug
            front {
              slug
              type
              subtype
              sphere
              title
              text
              flavorText
              threatCost
              resourceCost
              willpower
              attack
              defense
              hitPoints
              isUnique
              victoryPoints
              questPoints
              traits
              keywords
              maxPerDeck
              direction
              engagementCost
              orientation
              stage
              questPoints
              shadowEffect
              threatStrength
            }
            back {
              slug
              type
              subtype
              sphere
              title
              text
              flavorText
              threatCost
              resourceCost
              willpower
              attack
              defense
              hitPoints
              isUnique
              victoryPoints
              questPoints
              traits
              keywords
              maxPerDeck
              direction
              engagementCost
              orientation
              stage
              questPoints
              shadowEffect
              threatStrength
            }
            versions: productCards {
              product {
                code
                name
                abbreviation
                expansionSymbol
                type
              }
              number
              quantity
              frontImageUrl
              backImageUrl
            }
          }
        }
      }
    }
  }
`

const userQuery = gql`
  query {
    user {
      id
      profile {
        username
      }
      products {
        quantity
        productCode
      }
    }
  }
`

const collectionStatisticsQuery = gql`
  query {
    collectionStatistics {
      encounterCards {
        count
        cycle
        type
      }
      playerCards {
        count
        resourceCost
        sphere
        type
      }
    }
  }
`

export {
  cardBySlugQuery,
  randomCardsQuery,
  cardsByTitleQuery,
  cardNamesByTitleQuery,
  cardSearchQuery,
  allProductsQuery,
  productDetailsQuery,
  deckSearchQuery,
  randomDecksQuery,
  userQuery,
  collectionStatisticsQuery,
}
