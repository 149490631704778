import {
  OperationVariables,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client'
import {
  AddIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  MinusIcon,
} from '@chakra-ui/icons'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Alert,
  AlertIcon,
  Badge,
  Box,
  Card,
  CardHeader,
  CardBody,
  Container,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Progress,
  SimpleGrid,
  Spacer,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  useDisclosure,
  useToken,
  VStack,
} from '@chakra-ui/react'
import { useMemo, useState } from 'react'

import { collectionStatisticsQuery } from '../../Queries'
import { CardStatistics, Sphere } from '../../Types'

function CollectionsStatistics() {
  const { isOpen, getDisclosureProps, getButtonProps } = useDisclosure()
  const buttonProps = getButtonProps()
  const disclosureProps = getDisclosureProps()

  const collectionStatistics = useQuery(collectionStatisticsQuery, {
    skip: !isOpen,
  })

  const aggregateStats = useMemo(() => {
    const cardStats: CardStatistics =
      collectionStatistics.data?.collectionStatistics

    if (!cardStats) return {}

    const totalPlayerCards = cardStats.playerCards.reduce(
      (x, y) => x + y.count,
      0
    )
    const totalEncounterCards = cardStats.encounterCards.reduce(
      (x, y) => x + y.count,
      0
    )

    const playerCardsBySphere = cardStats.playerCards.reduce((agg, val) => {
      const existingEntry = agg.find((x) => x.label === val.sphere)
      if (existingEntry) existingEntry.count += val.count
      else agg.push({ label: val.sphere, count: val.count })
      return agg
    }, [] as { label: string; count: number }[])

    const playerCardsByResourceCost = cardStats.playerCards.reduce(
      (agg, val) => {
        const existingEntry = agg.find(
          (x) => x.label === (val.resourceCost?.toString() ?? 'null')
        )
        if (existingEntry) existingEntry.count += val.count
        else
          agg.push({
            label: val.resourceCost?.toString() ?? 'null',
            count: val.count,
          })
        return agg
      },
      [] as { label: string; count: number }[]
    )

    const playerCardsByType = cardStats.playerCards.reduce((agg, val) => {
      const existingEntry = agg.find((x) => x.label === val.type.toString())
      if (existingEntry) existingEntry.count += val.count
      else agg.push({ label: val.type.toString(), count: val.count })
      return agg
    }, [] as { label: string; count: number }[])

    const encounterCardsByCycle = cardStats.encounterCards.reduce(
      (agg, val) => {
        const existingEntry = agg.find(
          (x) => x.label === (val.cycle ?? 'Not from a cycle')
        )
        if (existingEntry) existingEntry.count += val.count
        else
          agg.push({ label: val.cycle ?? 'Not from a cycle', count: val.count })
        return agg
      },
      [] as { label: string; count: number }[]
    )

    const encounterCardsByType = cardStats.encounterCards.reduce((agg, val) => {
      const existingEntry = agg.find((x) => x.label === val.type.toString())
      if (existingEntry) existingEntry.count += val.count
      else agg.push({ label: val.type.toString(), count: val.count })
      return agg
    }, [] as { label: string; count: number }[])

    return {
      totalCards: totalPlayerCards + totalEncounterCards,
      totalPlayerCards,
      totalEncounterCards,
      playerCardsBySphere,
      playerCardsByResourceCost,
      playerCardsByType,
      encounterCardsByCycle,
      encounterCardsByType,
    }
  }, [collectionStatistics])

  return (
    <Card>
      <CardHeader {...buttonProps}>
        <Heading size="md" display="flex">
          Collection Statistics
          {aggregateStats.totalCards && (
            <Text fontStyle="italic" ml={2}>
              ({aggregateStats.totalCards} cards total)
            </Text>
          )}
          <Spacer />
          {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </Heading>
      </CardHeader>
      <Divider {...disclosureProps} />
      <CardBody {...disclosureProps} p={{ base: 2, md: 4 }}>
        {collectionStatistics.loading ? (
          <VStack my="20" spacing="4">
            <Spinner size="xl" color="brand" />
            <Text color="brand">Loading...</Text>
          </VStack>
        ) : (
          <SimpleGrid minChildWidth="400px" spacing="8">
            <VStack spacing={4} alignItems="stretch">
              <Heading as="h2" size="md">
                {aggregateStats.totalPlayerCards} player cards
              </Heading>

              <Tabs>
                <TabList>
                  <Tab>By sphere</Tab>
                  <Tab>By resource cost</Tab>
                  <Tab>By type</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <StatisticsTable
                      data={aggregateStats.playerCardsBySphere ?? []}
                    />
                  </TabPanel>
                  <TabPanel>
                    <StatisticsTable
                      data={aggregateStats.playerCardsByResourceCost ?? []}
                    />
                  </TabPanel>
                  <TabPanel>
                    <StatisticsTable
                      data={aggregateStats.playerCardsByType ?? []}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </VStack>
            <VStack spacing={4} alignItems="stretch">
              <Heading as="h2" size="md">
                {aggregateStats.totalEncounterCards} encounter cards
              </Heading>

              <Tabs>
                <TabList>
                  <Tab>By cycle</Tab>
                  <Tab>By type</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <StatisticsTable
                      data={aggregateStats.encounterCardsByCycle ?? []}
                    />
                  </TabPanel>
                  <TabPanel>
                    <StatisticsTable
                      data={aggregateStats.encounterCardsByType ?? []}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </VStack>
          </SimpleGrid>
        )}
      </CardBody>
    </Card>
  )
}

function StatisticsTable({
  data,
}: {
  data: { label: string; count: number }[]
}) {
  const max = Math.max(...data?.map((x) => x.count), 0)
  return (
    <TableContainer width="100%">
      <Table size="sm" variant="simple" width="100%">
        <Tbody>
          {data?.map((row) => (
            <Tr>
              <Td width="25%">{row.label}</Td>
              <Td width="10%" isNumeric>
                {row.count}
              </Td>
              <Td width="65%">
                <Progress width="100%" value={(100 * row.count) / max} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default CollectionsStatistics
