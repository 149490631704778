import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Card,
  Container,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  HStack,
  Input,
  Spinner,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { useContext, useEffect, useMemo, useState } from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import { AuthContext } from '../../auth/AuthContext'
import { login, logout } from '../../auth/AuthService'
import { trackEvent } from '../../matomo'
import { updateUserProfile } from '../../Mutations'
import { userQuery } from '../../Queries'
import { UserContext } from '../../user/UserContext'

const ManageProfile = () => {
  const { accessToken } = useContext(AuthContext)
  const { loading, error, data } = useQuery(userQuery)
  const [mutateFunction, _mutation] = useMutation(updateUserProfile)

  const [username, setUsername] = useState(null as string | null)

  useEffect(() => {
    setUsername(data?.user?.profile?.username)
  }, [data?.user?.profile?.username])

  if (!accessToken) return <Navigate replace to="/" />

  if (loading)
    return (
      <VStack mt="20" spacing="4">
        <Spinner size="xl" />
        <Text>Loading...</Text>
      </VStack>
    )

  return (
    <Box py={8} bgGradient="linear(to-tl, brand.900, brand.700)">
      <Container>
        <Card p={4}>
          <form
            onSubmit={(e) => {
              trackEvent('user', 'profile', 'username', username)
              mutateFunction({ variables: { username: username } })
              e.preventDefault()
            }}
          >
            <FormControl>
              <FormLabel>Username</FormLabel>
              <Input
                value={username ?? ''}
                onChange={(event) => setUsername(event.target.value)}
                placeholder="Choose something fun!"
                variant="filled"
              />
              <FormHelperText>
                This is how other users will see you.
                <br />
                You need a username before you can share any decks.
              </FormHelperText>
            </FormControl>
            <HStack justifyContent="flex-end">
              <Button mt={4} colorScheme="brand" type="submit">
                Submit
              </Button>
            </HStack>
          </form>
        </Card>
      </Container>
    </Box>
  )
}

export default ManageProfile
