import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ReactNode, useContext, useEffect } from 'react'
import { Md5 } from 'ts-md5'

import { AuthContext, AuthContextRef } from '../auth/AuthContext'

const httpLink = createHttpLink({
  uri: 'https://api.lotr.cardgame.tools/graphql',
})

const authLink = setContext((_, { headers }) => {
  const accessToken = AuthContextRef.current?.accessToken

  const authHeader = accessToken
    ? { Authorization: `Bearer ${accessToken}` }
    : {}

  return {
    headers: {
      ...headers,
      ...authHeader,
    },
  }
})

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          advancedSearch: {
            keyArgs: ['title'],
            merge(existing = { nodes: [] }, incoming) {
              return {
                totalCount: incoming.totalCount,
                pageInfo: incoming.pageInfo,
                nodes: [...existing.nodes, ...incoming.nodes],
              }
            },
          },
        },
      },
    },
  }),
})

const ApolloProviderProxy = ({ children }: { children?: ReactNode }) => {
  // const { accessToken } = useContext(AuthContext)

  // useEffect(() => {
  //   apolloClient.refetchQueries({ include: 'all' })
  // }, [accessToken])

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default ApolloProviderProxy
