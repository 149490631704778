import { Box, Image, SimpleGrid } from '@chakra-ui/react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import type { CardVersionWithCard } from '../../Types'

function ThinSpace() {
  return <span style={{ fontSize: '0.2em' }}> </span>
}

function CardGridImages({ cardVersion }: { cardVersion: CardVersionWithCard }) {
  const frontOrientation = cardVersion.card.front.orientation
  const backOrientation =
    cardVersion.card.back?.orientation ?? cardVersion.card.front.orientation
  const horizontalCard =
    frontOrientation == 'HORIZONTAL' && backOrientation == 'HORIZONTAL'

  const backImageUrl = cardVersion.backImageUrl
    ? cardVersion.backImageUrl.startsWith('http')
      ? cardVersion.backImageUrl
      : `https://images.cardgame.tools/lotr/sm/${cardVersion.backImageUrl}`
    : [
        'QUEST',
        'ENEMY',
        'SHIP_ENEMY',
        'LOCATION',
        'TREACHERY',
        'ENCOUNTER_SIDE_QUEST',
        'CAVE',
        'OBJECTIVE',
        'OBJECTIVE_HERO',
        'OBJECTIVE_ALLY',
        'OBJECTIVE_LOCATION',
        'SHIP_OBJECTIVE',
      ].includes(cardVersion.card.front.type) ||
      cardVersion.card.front.keywords?.includes('Encounter.')
    ? {
        HORIZONTAL: '/images/HorizontalEncounterBack.jpg',
        VERTICAL: '/images/VerticalEncounterBack.jpg',
      }[frontOrientation]
    : {
        HORIZONTAL: '/images/HorizontalPlayerBack.jpg',
        VERTICAL: '/images/VerticalPlayerBack.jpg',
      }[frontOrientation]

  const images = [
    <Image
      key="back"
      src={backImageUrl}
      maxHeight="250px"
      maxWidth="240px"
      borderRadius={14}
      boxShadow="0px 3px 8px 0px rgba(45,55,72,0.75), 0px 0px 3px 2px rgba(255,255,255,0.65)"
      transform="rotate(-2deg)"
      fallbackSrc={
        {
          HORIZONTAL: '/images/HorizontalPlaceholder.jpg',
          VERTICAL: '/images/VerticalPlaceholder.jpg',
        }[backOrientation]
      }
      position="absolute"
      top={
        {
          HORIZONTAL: '80px',
          VERTICAL: '0',
        }[backOrientation]
      }
    />,

    <Image
      key="front"
      src={
        cardVersion.frontImageUrl.startsWith('http')
          ? cardVersion.frontImageUrl
          : `https://images.cardgame.tools/lotr/sm/${cardVersion.frontImageUrl}`
      }
      maxHeight="250px"
      maxWidth="240px"
      borderRadius={14}
      boxShadow="0px 3px 8px 0px rgba(45,55,72,0.75), 0px 0px 3px 2px rgba(255,255,255,0.65)"
      transform="rotate(2deg)"
      fallbackSrc={
        {
          HORIZONTAL: '/images/HorizontalPlaceholder.jpg',
          VERTICAL: '/images/VerticalPlaceholder.jpg',
        }[cardVersion.card.front.orientation]
      }
      position="absolute"
      right="0px"
      top={
        {
          HORIZONTAL: '0',
          VERTICAL: '0',
        }[frontOrientation]
      }
    />,
  ]

  return <>{images}</>
}

function ProductCardImageGrid({ cards }: { cards: CardVersionWithCard[] }) {
  return (
    <SimpleGrid
      autoFlow="row"
      rowGap="2"
      columnGap="4"
      alignItems="center"
      minChildWidth="240px"
    >
      {cards
        .slice()
        .sort((a, b) => parseInt(a.number) - parseInt(b.number))
        .map((cardVersion, index) => (
          <NavLink to={`/card/${cardVersion.card.slug}`}>
            <Box position="relative" height="280px">
              <CardGridImages cardVersion={cardVersion} />
            </Box>
          </NavLink>
        ))}
    </SimpleGrid>
  )
}

export default ProductCardImageGrid
