import { SimpleGrid } from '@chakra-ui/react'

import LotrCard from '../../components/LotrCard'
import { CardVersionWithCard, CardWithVersions } from '../../Types'

function ProductCardList({ cards }: { cards: CardVersionWithCard[] }) {
  return (
    <SimpleGrid
      key="grid"
      minChildWidth={{ base: 'none', md: '650px' }}
      spacingX="12"
      spacingY="4"
      ml={{ base: 0, lg: 4 }}
    >
      {cards
        .slice()
        .sort((a, b) => parseInt(a.number) - parseInt(b.number))
        .map((card) => (
          <LotrCard key={card.card.slug} card={card.card as CardWithVersions} />
        ))}
    </SimpleGrid>
  )
}

export default ProductCardList
