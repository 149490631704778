import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Image,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useState } from 'react'

import type { CardWithVersions as CardType } from '../Types'
import LotrCardSide from './LotrCardSide'

function LotrCard({ card }: { card: CardType }) {
  const [flipped, flip] = useState(false)
  const [activeVersion, setVersion] = useState(card.versions[0])

  const visibleSide = card.back && flipped ? card.back : card.front

  const frontOrientation = card.front.orientation
  const backOrientation = card.back?.orientation ?? card.front.orientation
  const horizontalCard =
    frontOrientation == 'HORIZONTAL' && backOrientation == 'HORIZONTAL'

  const frontImageUrl = activeVersion.frontImageUrl.startsWith('http')
    ? activeVersion.frontImageUrl
    : `https://images.cardgame.tools/lotr/sm/${activeVersion.frontImageUrl}`

  const backImageUrl = activeVersion.backImageUrl
    ? activeVersion.backImageUrl.startsWith('http')
      ? activeVersion.backImageUrl
      : `https://images.cardgame.tools/lotr/sm/${activeVersion.backImageUrl}`
    : ([
        'QUEST',
        'ENEMY',
        'SHIP_ENEMY',
        'LOCATION',
        'TREACHERY',
        'ENCOUNTER_SIDE_QUEST',
        'CAVE',
        'OBJECTIVE',
        'OBJECTIVE_HERO',
        'OBJECTIVE_ALLY',
        'OBJECTIVE_LOCATION',
        'SHIP_OBJECTIVE',
      ].includes(card.front.type) ||
        card.front.keywords?.includes('Encounter.')) &&
      !card.front.text.includes('functions like a player card')
    ? {
        HORIZONTAL: '/images/HorizontalEncounterBack.jpg',
        VERTICAL: '/images/VerticalEncounterBack.jpg',
      }[frontOrientation]
    : {
        HORIZONTAL: '/images/HorizontalPlayerBack.jpg',
        VERTICAL: '/images/VerticalPlayerBack.jpg',
      }[frontOrientation]

  const imageProps = {
    maxHeight: '250px',
    maxWidth: '240px',
    borderRadius: 14,
    boxShadow:
      '0px 3px 8px 0px rgba(45,55,72,0.75), 0px 0px 3px 2px rgba(255,255,255,0.25)',
    position: 'absolute' as any,
  }

  let backImage = (
    <Image
      key="back"
      src={backImageUrl}
      transform="rotate(-2deg)"
      fallbackSrc={
        {
          HORIZONTAL: '/images/HorizontalPlaceholder.jpg',
          VERTICAL: '/images/VerticalPlaceholder.jpg',
        }[card.back?.orientation ?? card.front.orientation]
      }
      left={{
        base: '',
        lg: '-15px',
      }}
      top={{
        base: {
          HORIZONTAL: '50px',
          VERTICAL: '0',
        }[card.back?.orientation ?? card.front.orientation],
        lg: {
          HORIZONTAL: '80px',
          VERTICAL: '10px',
        }[card.back?.orientation ?? card.front.orientation],
      }}
      {...imageProps}
    />
  )

  let frontImage = (
    <Image
      key="front"
      src={frontImageUrl}
      transform="rotate(2deg)"
      fallbackSrc={
        {
          HORIZONTAL: '/images/HorizontalPlaceholder.jpg',
          VERTICAL: '/images/VerticalPlaceholder.jpg',
        }[card.front.orientation]
      }
      left={{
        base: '',
        lg: {
          HORIZONTAL: '-15px',
          VERTICAL: '45px',
        }[card.back?.orientation ?? card.front.orientation],
      }}
      right={{ base: '0px', lg: '' }}
      top={{
        base: {
          HORIZONTAL: '50px',
          VERTICAL: '0',
        }[card.back?.orientation ?? card.front.orientation],
        lg: '0',
      }}
      {...imageProps}
    />
  )

  const images = [
    backImageUrl.startsWith('https://images.cardgame.tools/lotr/sm/') ? (
      <a href={backImageUrl.replace('/sm/', '/')} target="_blank">
        {backImage}
      </a>
    ) : (
      backImage
    ),

    frontImageUrl.startsWith('https://images.cardgame.tools/lotr/sm/') ? (
      <a href={frontImageUrl.replace('/sm/', '/')} target="_blank">
        {frontImage}
      </a>
    ) : (
      frontImage
    ),
  ]

  const bottomOptions = (
    <HStack justifyContent="flex-end" spacing={4} px={4}>
      <ButtonGroup
        isAttached
        variant="outline"
        colorScheme="brand"
        size="sm"
        boxShadow="base"
        bg="gray.200"
        flexWrap="wrap"
        borderBottomRadius="md"
      >
        {card.versions?.map((version) => (
          <Tooltip
            key={version.product.abbreviation}
            label={`Card #${version.number} in ${version.product.name}${
              version.product.type == 'NIGHTMARE_EXPANSION'
                ? ' (Nightmare Deck)'
                : ''
            }`}
            hasArrow
            placement="top"
          >
            <Button
              borderTopRadius={0}
              border="none"
              bg={
                activeVersion.number == version.number &&
                activeVersion.product.code == version.product.code
                  ? 'white'
                  : 'transparent'
              }
              color="brand.800"
              borderColor="brand.100"
              _hover={{ bg: 'brand.400', color: 'white' }}
              onClick={() => setVersion(version)}
            >
              {version.quantity}x in {version.product.abbreviation}
            </Button>
          </Tooltip>
        ))}
      </ButtonGroup>
      {card.back && (
        <Button
          variant="solid"
          colorScheme="brand"
          boxShadow="base"
          bg="brand.200"
          color="white"
          borderTopRadius={0}
          borderTop="none"
          size="sm"
          minWidth="85px"
          onClick={() => flip(!flipped)}
        >
          See {flipped ? 'front' : 'back'}
        </Button>
      )}
    </HStack>
  )

  const leftStretch = useBreakpointValue({
    base: '0px',
    lg: '100px',
  })

  return (
    <Box
      display="flex"
      flexDirection={{ base: 'column', lg: 'row' }}
      alignItems="stretch"
    >
      <Box
        height={200}
        width={{ base: 'auto', lg: '225px' }}
        maxWidth={{ base: '400px', lg: 'unset' }}
        position="relative"
        flexShrink={0}
        zIndex={{ base: '300', lg: '700' }}
        top={-2}
      >
        {flipped ? [images[1], images[0]] : images}
      </Box>
      <Box flexGrow={1} zIndex="500">
        <LotrCardSide
          side={visibleSide}
          slug={card.slug}
          leftStretch={leftStretch!}
        />
        {bottomOptions}
      </Box>
    </Box>
  )
}

export default LotrCard
