import { Tag } from '@chakra-ui/react'
import { subtypeLookup, typeLookup } from '../helpers/enumLookups'

function TypeTag({
  type,
  subtype,
}: {
  type: string
  subtype: string | undefined
}) {
  return (
    <>
      <Tag
        variant="subtle"
        colorScheme="gray"
        bg="white"
        borderColor="gray.100"
        borderWidth={0.5}
      >
        {typeLookup(type)}
        {subtype && ` - ${subtypeLookup(subtype)}`}
      </Tag>
    </>
  )
}

export default TypeTag
