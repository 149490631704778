import {
  Box,
  Container,
  Flex,
  Input,
  useColorModeValue,
} from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'

import { randomCardsQuery, cardsByTitleQuery } from '../../Queries'
import SearchBox from './SearchBox'
import SearchResults from './SearchResults'
import useSearchResults from './useSearchResults'

const SimpleSearch = () => {
  const [syncedData, bouncySearchParams, setSearchParams] = useSearchResults(
    randomCardsQuery,
    cardsByTitleQuery
  )

  const results = useMemo(() => <SearchResults {...syncedData} />, [syncedData])

  return (
    <Box bgGradient="linear(to-tl, brand.900, brand.700)">
      <SearchBox
        queryString={bouncySearchParams}
        setQueryString={setSearchParams}
      />
      {results}
    </Box>
  )
}

export default SimpleSearch
