import { useQuery } from '@apollo/client'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  HStack,
  Spinner,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { ReactElement, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { allProductsQuery } from '../../Queries'
import ProductList from './ProductList'
import Sidebar from './SideBar'

function Layout({
  sidebar,
  productList,
  productsSelected,
  setActiveProductCodes,
}: {
  sidebar: ReactElement
  productList: ReactElement
  productsSelected: boolean
  setActiveProductCodes: (productCodes: string[]) => void
}) {
  const showProductDetails = productsSelected
  const showProductList = !showProductDetails

  const mobileView = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    {
      ssr: false,
    }
  )

  return (
    <Flex
      flexDirection={{
        base: 'column',
        xl: 'row',
      }}
      justifyContent="flex-start"
      alignItems={{ base: 'normal', xl: 'stretch' }}
      /*height={{ base: 'initial', xl: 'calc(100vh - 60px)' }}*/
      minHeight="100vh"
      bgGradient="linear(to-tl, brand.900, brand.700)"
    >
      <Box
        color="white"
        bgGradient="linear(to-r, gray.800, gray.900)"
        width={{ base: '100%', xl: '380px' }}
        minHeight={mobileView ? (showProductList ? '100vh' : 'unset') : '100%'}
        flexShrink="0"
        px={8}
        py={4}
        display="flex"
        flexDirection="column"
        min-height="100%"
        overflowX="hidden"
        overflowY="auto"
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        <>
          {mobileView && showProductDetails && (
            <HStack
              width="100%"
              justifyContent="space-between"
              alignItems="baseline"
              onClick={() => setActiveProductCodes([])}
            >
              <Text fontSize="lg" fontWeight="bold">
                Show product list
              </Text>
              <ChevronDownIcon />
            </HStack>
          )}

          {(!mobileView || showProductList) && sidebar}
        </>
      </Box>

      <Box
        width={{ base: 'unset', xl: '100%' }}
        flexGrow="1"
        overflowX="hidden"
        overflowY="auto"
      >
        {productsSelected && productList}
      </Box>
    </Flex>
  )
}

function ProductListing() {
  const productsQuery = useQuery(allProductsQuery)

  const [searchParams, setSearchParams] = useSearchParams()

  const activeProductCodes = useMemo(() => {
    return (searchParams
      .get('codes')
      ?.split('+')
      .filter((x) => x != '') ?? []) as string[]
  }, [searchParams])

  const setActiveProductCodes = (productCodes: string[]) => {
    setSearchParams({ codes: productCodes.join('+') })
  }

  const sidebar = useMemo(
    () => (
      <>
        {productsQuery.loading ? (
          <VStack mt="20" spacing="4">
            <Spinner size="xl" />
            <Text>Loading...</Text>
          </VStack>
        ) : (
          <Sidebar
            products={productsQuery?.data?.productList ?? []}
            activeProductCodes={activeProductCodes}
            setActiveProductCodes={setActiveProductCodes}
          />
        )}
      </>
    ),
    [productsQuery, activeProductCodes, setActiveProductCodes]
  )

  return (
    <Layout
      sidebar={sidebar}
      productList={<ProductList />}
      productsSelected={activeProductCodes.length > 0}
      setActiveProductCodes={setActiveProductCodes}
    />
  )
}

export default ProductListing
