import { ReactNode, useEffect, useImperativeHandle, useState } from 'react'

import { AuthContext, AuthContextRef } from './AuthContext'
import { getUser } from './AuthService'

const AuthProvider = ({ children }: { children?: ReactNode }) => {
  const [accessToken, setAccessToken] = useState(null as string | null)

  useEffect(() => {
    // Populate on initial load
    ;(async () => {
      const authUser = await getUser()
      setAccessToken(authUser?.access_token ?? null)
    })()
  }, [])

  const contextValue = {
    accessToken,
    setAccessToken,
  }

  useImperativeHandle(AuthContextRef, () => contextValue)

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export default AuthProvider
