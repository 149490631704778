import { useQuery } from '@apollo/client'
import { Box, Container, Spinner, Text, VStack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

import LotrCard from '../../components/LotrCard'
import { cardBySlugQuery } from '../../Queries'
import { CardWithVersions } from '../../Types'

export default () => {
  const { slug } = useParams()

  const {
    loading,
    error,
    data: card,
  } = useQuery(cardBySlugQuery, {
    variables: {
      slug: slug,
    },
  })

  if (loading)
    return (
      <VStack mt="20" spacing="4">
        <Spinner size="xl" />
        <Text>Loading...</Text>
      </VStack>
    )

  if (error) return <>Error! ${error.message}</>

  return (
    <Box
      width="100%"
      minHeight="calc(100vh - 60px)"
      bgGradient="linear(to-tl, brand.900, brand.700)"
    >
      <Container width="100vw" maxWidth="800px" py="16">
        <LotrCard card={card.card as CardWithVersions} />
      </Container>
    </Box>
  )
}
