import { HamburgerIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  HStack,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
  useDisclosure,
  Spacer,
  VStack,
  MenuDivider,
} from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'

import { ReactComponent as CGTLogo } from '../svgs/CGT_Logo.svg'
import NavbarUserMenu from './NavbarUserMenu'

function Link({ path, label }: { path: string; label: string }) {
  return (
    <NavLink to={path}>
      {({ isActive }) => (
        <Button
          colorScheme="brand"
          variant={'solid'}
          bg={isActive ? 'brand.700' : 'gray.400'}
          minWidth="150px"
        >
          {label}
        </Button>
      )}
    </NavLink>
  )
}

function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const mobileMenu = useBreakpointValue(
    { base: true, lg: false },
    { ssr: false }
  )!

  return (
    <Box
      bg="gray.100"
      py={2}
      px={4}
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
    >
      <Flex alignItems="center" color="brand.00">
        <CGTLogo height="40px" />
        <VStack alignItems="flex-start" spacing="0">
          <NavLink to={'/'}>
            <Text
              fontSize="lg"
              fontWeight="bold"
              mb={-1}
              _hover={{ textDecoration: 'underline' }}
            >
              Lord of the Rings LCG
            </Text>
          </NavLink>
          <Text fontSize="sm">by CardGame.Tools</Text>
        </VStack>
        <Spacer />
        {mobileMenu ? (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Menu"
              icon={<HamburgerIcon />}
              variant="solid"
              colorScheme="brand"
            />
            <MenuList zIndex="1000">
              <NavLink to="/cards">
                <MenuItem>Find cards</MenuItem>
              </NavLink>
              <NavLink to="/products">
                <MenuItem>Browse products</MenuItem>
              </NavLink>
              <NavLink to="/decks">
                <MenuItem>Find decks (β)</MenuItem>
              </NavLink>
              <MenuDivider />
              <NavbarUserMenu mobile={mobileMenu} />
            </MenuList>
          </Menu>
        ) : (
          <HStack spacing={4}>
            <Link path="/cards" label="Find cards" />
            <Link path="/products" label="Browse products" />
            <Link path="/decks" label="Find decks (β)" />
            <NavbarUserMenu mobile={mobileMenu} />
          </HStack>
        )}
      </Flex>
    </Box>
  )
}

export default Navbar
