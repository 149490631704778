import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Card,
  Container,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  HStack,
  Input,
  Spinner,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { useContext, useEffect, useMemo, useState } from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import { AuthContext } from '../../auth/AuthContext'
import { login, logout } from '../../auth/AuthService'
import { updateUserProfile } from '../../Mutations'
import {
  allProductsQuery,
  collectionStatisticsQuery,
  userQuery as userGraphQLQuery,
} from '../../Queries'
import type { Product, UserProduct } from '../../Types'
import { UserContext } from '../../user/UserContext'
import CollectionStatistics from './CollectionStatistics'
import ProductList from './ProductList'

const ManageCollection = () => {
  const apolloClient = useApolloClient()
  const { accessToken } = useContext(AuthContext)
  const productsQuery = useQuery(allProductsQuery)
  const userQuery = useQuery(userGraphQLQuery)

  const products: Product[] = productsQuery?.data?.productList ?? []
  const userProducts: { productCode: String; quantity: number }[] =
    userQuery?.data?.user?.products ?? []

  const mergedProducts: UserProduct[] = useMemo(() => {
    apolloClient.refetchQueries({ include: [collectionStatisticsQuery] })
    return products.map((p) => {
      return {
        ...p,
        quantity:
          userProducts.find((up) => up.productCode == p.code)?.quantity ?? 0,
      }
    })
  }, [userProducts, products])

  if (!accessToken) return <Navigate replace to="/" />

  return (
    <Box
      p={{ base: 4, md: 8 }}
      bgGradient="linear(to-tl, brand.900, brand.700)"
    >
      {productsQuery.loading || userQuery.loading ? (
        <VStack my="20" spacing="4">
          <Spinner size="xl" color="white" />
          <Text color="white">Loading...</Text>
        </VStack>
      ) : (
        <VStack spacing={{ base: 4, md: 8 }} alignItems="stretch">
          <CollectionStatistics />
          <ProductList userProducts={mergedProducts} />
        </VStack>
      )}
    </Box>
  )
}

export default ManageCollection
