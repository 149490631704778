import { useQuery } from '@apollo/client'
import type { DocumentNode, QueryResult } from '@apollo/client'
import { useEffect, useMemo, useRef, useState, MutableRefObject } from 'react'
import useDebounce from '../../hooks/useDebounce'

export default function useSearchResults(
  randomQuery: DocumentNode,
  searchQuery: DocumentNode
): [
  {
    showRandomCards: boolean
    results:
      | QueryResult<
          any,
          {
            title: any
            first: number
          }
        >
      | QueryResult<any, {}>
  },
  string,
  React.Dispatch<React.SetStateAction<string>>
] {
  const [bouncySearchParams, setSearchParams] = useState('')
  const searchInput = useDebounce(bouncySearchParams, 200)

  const searchResults = useQuery(searchQuery, {
    variables: {
      title: searchInput,
      first: 20,
    },
  })

  const randomCards = useQuery(randomQuery, { variables: {} })

  const showRandomCards = useMemo(() => searchInput === '', [searchInput])
  const loading = useMemo(
    () => (showRandomCards ? randomCards.loading : searchResults.loading),
    [searchResults, randomCards, showRandomCards]
  )

  const lastShown: MutableRefObject<{
    showRandomCards: boolean
    results:
      | QueryResult<
          any,
          {
            title: any
            first: number
          }
        >
      | QueryResult<any, {}>
  }> = useRef({
    showRandomCards: true,
    results: randomCards,
  })

  const syncedData: {
    showRandomCards: boolean
    results:
      | QueryResult<
          any,
          {
            title: any
            first: number
          }
        >
      | QueryResult<any, {}>
  } = useMemo(
    () =>
      loading
        ? lastShown.current
        : showRandomCards
        ? {
            showRandomCards: true,
            results: randomCards,
          }
        : {
            showRandomCards: false,
            results: searchResults,
          },
    [loading, lastShown, searchResults, randomCards, showRandomCards]
  )

  useEffect(() => {
    lastShown.current = syncedData
  }, [syncedData])

  return [syncedData, bouncySearchParams, setSearchParams]
}
