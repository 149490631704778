import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  AddIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  MinusIcon,
} from '@chakra-ui/icons'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Alert,
  AlertIcon,
  Badge,
  Box,
  Card,
  CardHeader,
  CardBody,
  Container,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Spacer,
  Text,
  useDisclosure,
  useToken,
  VStack,
} from '@chakra-ui/react'
import { useMemo, useState } from 'react'

import ExpansionSymbol from '../../components/ExpansionSymbol'
import { trackEvent } from '../../matomo'
import { updateUserProduct, updateUserProducts } from '../../Mutations'
import type { Product, UserProduct } from '../../Types'

function ProductList({ userProducts }: { userProducts: UserProduct[] }) {
  const repackedProducts = useMemo(() => {
    const products = userProducts.filter(
      (p) => p.category === 'OFFICIAL' && p.isRepackage
    )

    return [
      {
        title: 'Core Set',
        userProducts: products.filter((p) => p.type === 'CORE'),
      },
      {
        title: 'Starter Decks',
        userProducts: products.filter((p) => p.type === 'STARTER_DECK'),
      },
      {
        title: 'Scenario Packs',
        userProducts: products.filter((p) => p.type === 'SCENARIO_PACK'),
      },
      {
        title: 'Angmar Awakened Cycle',
        userProducts: products.filter(
          (p) =>
            p.cycle === 'Angmar Awakened' &&
            ['CAMPAIGN_EXPANSION', 'HERO_EXPANSION'].includes(p.type)
        ),
      },
      {
        title: 'Dream-chaser Cycle',
        userProducts: products.filter(
          (p) =>
            p.cycle === 'Dream-chaser' &&
            ['CAMPAIGN_EXPANSION', 'HERO_EXPANSION'].includes(p.type)
        ),
      },
      {
        title: 'The Lord of the Rings Sagas',
        userProducts: products.filter(
          (p) =>
            p.cycle === 'The Lord of the Rings' && p.type === 'SAGA_EXPANSION'
        ),
      },
    ]
  }, [userProducts])

  const originalProducts = useMemo(() => {
    const products = userProducts.filter(
      (p) => p.category === 'OFFICIAL' && !p.isRepackage
    )

    return [
      {
        title: 'Core Sets',
        userProducts: products.filter((p) => p.type === 'CORE'),
      },

      {
        title: 'Shadows of Mirkwood Cycle',
        userProducts: products.filter(
          (p) =>
            p.cycle === 'Shadows of Mirkwood' &&
            ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
        ),
      },

      {
        title: 'Dwarrowdelf Cycle',
        userProducts: products.filter(
          (p) =>
            p.cycle === 'Dwarrowdelf' &&
            ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
        ),
      },

      {
        title: 'Against the Shadow Cycle',
        userProducts: products.filter(
          (p) =>
            p.cycle === 'Against the Shadow' &&
            ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
        ),
      },

      {
        title: 'The Ring-maker Cycle',
        userProducts: products.filter(
          (p) =>
            p.cycle === 'The Ring-maker' &&
            ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
        ),
      },

      {
        title: 'Angmar Awakened Cycle',
        userProducts: products.filter(
          (p) =>
            p.cycle === 'Angmar Awakened' &&
            ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
        ),
      },

      {
        title: 'Dream-chaser Cycle',
        userProducts: products.filter(
          (p) =>
            p.cycle === 'Dream-chaser' &&
            ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
        ),
      },

      {
        title: 'Haradrim Cycle',
        userProducts: products.filter(
          (p) =>
            p.cycle === 'Haradrim' &&
            ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
        ),
      },

      {
        title: 'Ered Mithrin Cycle',
        userProducts: products.filter(
          (p) =>
            p.cycle === 'Ered Mithrin' &&
            ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
        ),
      },

      {
        title: 'Vengeance of Mordor Cycle',
        userProducts: products.filter(
          (p) =>
            p.cycle === 'Vengeance of Mordor' &&
            ['DELUXE_EXPANSION', 'ADVENTURE_PACK'].includes(p.type)
        ),
      },

      {
        title: 'The Hobbit Sagas',
        userProducts: products.filter(
          (p) => p.cycle === 'The Hobbit' && p.type === 'SAGA_EXPANSION'
        ),
      },

      {
        title: 'The Lord of the Rings Sagas',
        userProducts: products.filter(
          (p) =>
            p.cycle === 'The Lord of the Rings' && p.type === 'SAGA_EXPANSION'
        ),
      },

      {
        title: 'Nightmare Decks',
        userProducts: products.filter((p) => p.type === 'NIGHTMARE_EXPANSION'),
      },

      {
        title: 'Standalone Scenarios',
        userProducts: products.filter((p) => p.type === 'STANDALONE_SCENARIO'),
      },
    ]
  }, [userProducts])

  const { isOpen, getDisclosureProps, getButtonProps } = useDisclosure()
  const buttonProps = getButtonProps()
  const disclosureProps = getDisclosureProps()

  const hasRepackagedProducts = repackedProducts.some((x) =>
    x.userProducts.some((y) => y.quantity > 0)
  )
  const hasOriginalProduct = originalProducts.some((x) =>
    x.userProducts.some((y) => y.quantity > 0)
  )

  return (
    <>
      <ProductCategory
        title="Repackaged Products"
        productGroups={repackedProducts}
        forceInitialOpen={!(hasRepackagedProducts || hasOriginalProduct)}
      />
      <ProductCategory
        title="Original Products"
        productGroups={originalProducts}
      />
    </>
  )
}

function ProductCategory({
  title,
  productGroups,
  forceInitialOpen,
}: {
  title: string
  productGroups: {
    title: string
    userProducts: UserProduct[]
  }[]
  forceInitialOpen?: boolean | null
}) {
  const { isOpen, getDisclosureProps, getButtonProps } = useDisclosure({
    defaultIsOpen: (() => {
      return (
        forceInitialOpen ||
        productGroups.some((x) => x.userProducts.some((y) => y.quantity > 0))
      )
    })(),
  })
  const buttonProps = getButtonProps()
  const disclosureProps = getDisclosureProps()

  return (
    <Card>
      <CardHeader {...buttonProps}>
        <Heading size="md" display="flex">
          {title}
          <Spacer />
          {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </Heading>
      </CardHeader>
      <Divider {...disclosureProps} />
      <CardBody
        {...disclosureProps}
        p={{ base: 2, md: 4 }}
        sx={{ columnCount: { base: '1', lg: '2' } }}
      >
        {productGroups.map((pg) => (
          <ProductGroup key={pg.title} {...pg} />
        ))}
      </CardBody>
    </Card>
  )
}

function ProductGroup({
  title,
  userProducts,
}: {
  title: string
  userProducts: UserProduct[]
}) {
  const [mutateFunction, _mutation] = useMutation(updateUserProducts)

  const minQty = Math.min(...userProducts.map((up) => up.quantity))
  const maxQty = Math.max(...userProducts.map((up) => up.quantity))

  const [loading, setLoading] = useState(false)

  return (
    <Box mt={3} sx={{ breakInside: 'avoid' }} _first={{ marginTop: 0 }}>
      <HStack py={2}>
        <HStack
          bg={
            userProducts.every((up) => up.quantity > 0)
              ? 'teal.300'
              : userProducts.some((up) => up.quantity > 0)
              ? 'teal.100'
              : ''
          }
          p={1}
          borderRadius={6}
        >
          <IconButton
            aria-label="Decrease quantity"
            icon={<MinusIcon />}
            size="xs"
            isDisabled={loading}
            onClick={(e) => {
              setLoading(true)
              trackEvent('user', 'collection', title, 'decrease')
              mutateFunction({
                variables: {
                  userProducts: userProducts.map((up) => {
                    return {
                      productCode: up.code,
                      quantity: Math.max(up.quantity - 1, 0),
                    }
                  }),
                },
              }).finally(() => setLoading(false))
            }}
          />
          <Text fontFamily="monospace" width="1.3em" textAlign="center">
            {minQty}
            {maxQty > minQty && '+'}
          </Text>
          <IconButton
            aria-label="Increase quantity"
            icon={<AddIcon />}
            size="xs"
            isDisabled={loading}
            onClick={(e) => {
              setLoading(true)
              trackEvent('user', 'collection', title, 'increase')
              mutateFunction({
                variables: {
                  userProducts: userProducts.map((up) => {
                    return {
                      productCode: up.code,
                      quantity: up.quantity + 1,
                    }
                  }),
                },
              }).finally(() => setLoading(false))
            }}
          />
        </HStack>
        <Heading as="h2" size="md" mb={2}>
          {title}
        </Heading>
      </HStack>
      <VStack spacing={0.5} alignItems="stretch">
        {userProducts.map((up) => (
          <UserProductDisplay
            key={up.code}
            userProduct={up}
            disableInputs={loading}
          />
        ))}
      </VStack>
    </Box>
  )
}

function UserProductDisplay({
  userProduct,
  disableInputs,
}: {
  userProduct: UserProduct
  disableInputs: boolean
}) {
  const [mutateFunction, _mutation] = useMutation(updateUserProduct)
  const [loading, setLoading] = useState(false)

  return (
    <HStack alignItems="center">
      <HStack
        bg={userProduct.quantity > 0 ? 'teal.300' : ''}
        p={1}
        borderRadius={6}
      >
        <IconButton
          aria-label="Decrease quantity"
          icon={<MinusIcon />}
          size="xs"
          isDisabled={loading || disableInputs}
          onClick={(e) => {
            setLoading(true)
            trackEvent('user', 'collection', userProduct.code, 'decrease')
            mutateFunction({
              variables: {
                productCode: userProduct.code,
                quantity: Math.max(userProduct.quantity - 1, 0),
              },
            }).finally(() => setLoading(false))
          }}
        />
        <Text fontFamily="monospace" width="1.3em" textAlign="center">
          {userProduct.quantity}
        </Text>
        <IconButton
          aria-label="Increase quantity"
          icon={<AddIcon />}
          size="xs"
          isDisabled={loading || disableInputs}
          onClick={(e) => {
            setLoading(true)
            trackEvent('user', 'collection', userProduct.code, 'increase')
            mutateFunction({
              variables: {
                productCode: userProduct.code,
                quantity: userProduct.quantity + 1,
              },
            }).finally(() => setLoading(false))
          }}
        />
      </HStack>
      <Badge colorScheme="gray">{userProduct.code}</Badge>
      {userProduct.expansionSymbol && (
        <ExpansionSymbol
          expansionSymbol={userProduct.expansionSymbol}
          height="20px"
          width="20px"
          src={`/icons/expansion symbols/${userProduct.expansionSymbol}`}
        />
      )}
      <Text>{userProduct.name}</Text>
    </HStack>
  )
}

export default ProductList
