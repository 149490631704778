import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Container,
  Flex,
  Input,
  useColorModeValue,
} from '@chakra-ui/react'

export default ({
  queryString,
  setQueryString,
}: {
  queryString: string
  setQueryString: React.Dispatch<React.SetStateAction<string>>
}) => {
  return (
    <Box>
      <Container
        height="300px"
        maxWidth="60em"
        display="flex"
        alignItems="center"
        px={8}
        pt={8}
      >
        <Input
          placeholder="Find by title"
          size="lg"
          p="8"
          bg="white"
          value={queryString}
          onChange={(event) => setQueryString(event.target.value)}
        />
      </Container>
    </Box>
  )
}
