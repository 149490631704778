import { Tag, Tooltip } from '@chakra-ui/react'
import { sphereLookup, sphereColors } from '../helpers/enumLookups'

import { ReactComponent as Baggins } from '../svgs/Baggins.svg'
import { ReactComponent as Fellowship } from '../svgs/Fellowship.svg'
import { ReactComponent as Leadership } from '../svgs/Leadership.svg'
import { ReactComponent as Lore } from '../svgs/Lore.svg'
import { ReactComponent as Mastery } from '../svgs/Mastery.svg'
import { ReactComponent as Spirit } from '../svgs/Spirit.svg'
import { ReactComponent as Tactics } from '../svgs/Tactics.svg'

function SphereTag({
  sphere,
  iconSize = 24,
  size = 'md',
}: {
  sphere: string
  iconSize?: number
  size?: string
}) {
  const { bg, color } = sphereColors(sphere)

  const neutralBgOverride = sphere == 'NEUTRAL' ? { bg: 'gray.300' } : {}

  return (
    <Tooltip
      label={sphereLookup(sphere)}
      bg={bg}
      color={color}
      placement="right"
    >
      <Tag
        colorScheme={bg}
        {...neutralBgOverride}
        justifyContent="center"
        py={0.5}
        px={1}
        sx={{
          '@media print': {
            color: 'black',
          },
        }}
        size={size}
      >
        {
          {
            BAGGINS: <Baggins width={iconSize} height={iconSize} />,
            FELLOWSHIP: <Fellowship width={iconSize} height={iconSize} />,
            LEADERSHIP: <Leadership width={iconSize} height={iconSize} />,
            LORE: <Lore width={iconSize} height={iconSize} />,
            MASTERY: <Mastery width={iconSize} height={iconSize} />,
            SPIRIT: <Spirit width={iconSize} height={iconSize} />,
            TACTICS: <Tactics width={iconSize} height={iconSize} />,
            NEUTRAL: <>N</>,
          }[sphere]
        }
      </Tag>
    </Tooltip>
  )
}

export default SphereTag
