import { Image } from '@chakra-ui/react'
import regexifyString from 'regexify-string'

import { ReactComponent as Baggins } from '../svgs/Baggins.svg'
import { ReactComponent as Fellowship } from '../svgs/Fellowship.svg'
import { ReactComponent as Leadership } from '../svgs/Leadership.svg'
import { ReactComponent as Lore } from '../svgs/Lore.svg'
import { ReactComponent as Mastery } from '../svgs/Mastery.svg'
import { ReactComponent as Spirit } from '../svgs/Spirit.svg'
import { ReactComponent as Tactics } from '../svgs/Tactics.svg'

import StatIconUrl from './statIconUrl'

const formatNumber = (value: number) =>
  value === 255 ? '-' : value === 254 ? 'X' : value === 253 ? '✽' : value

const Icon = (url: string, alt: string) => (
  <Image
    alt={alt}
    src={url}
    boxSize="16px"
    display="inline"
    position="relative"
    top="2px"
  />
)

const svgStyle: React.CSSProperties = {
  width: '16px',
  height: '16px',
  display: 'inline',
  position: 'relative',
  top: '2px',
}

const textReplacements = [
  {
    pattern: /When Revealed:/,
    replacement: <b>When Revealed:</b>,
  },
  {
    pattern: /Setup:/,
    replacement: <b>Setup:</b>,
  },
  {
    pattern: /Valour Planning Action:/,
    replacement: <b>Valour Planning Action:</b>,
  },
  {
    pattern: /Planning Action:/,
    replacement: <b>Planning Action:</b>,
  },
  {
    pattern: /Valour Action:/,
    replacement: <b>Valour Action:</b>,
  },
  {
    pattern: /Combat Action:/,
    replacement: <b>Combat Action:</b>,
  },
  {
    pattern: /Quest Action:/,
    replacement: <b>Quest Action:</b>,
  },
  {
    pattern: /Action:/,
    replacement: <b>Action:</b>,
  },
  {
    pattern: /Forced:/,
    replacement: <b>Forced:</b>,
  },
  {
    pattern: /Travel:/,
    replacement: <b>Travel:</b>,
  },
  {
    pattern: /Valour Response:/,
    replacement: <b>Valour Response:</b>,
  },
  {
    pattern: /Response:/,
    replacement: <b>Response:</b>,
  },
  {
    pattern: /Attack(?=\W)/,
    replacement: Icon(StatIconUrl('ATTACK'), 'Attack'),
  },
  {
    pattern: /Defense(?=\W)/,
    replacement: Icon(StatIconUrl('DEFENSE'), 'Defense'),
  },
  {
    pattern: /Hit Points(?=\W)/,
    replacement: Icon(StatIconUrl('HITPOINTS'), 'Hit Points'),
  },
  {
    pattern: /Threat(?=\W)/,
    replacement: Icon(StatIconUrl('THREAT'), 'Threat'),
  },
  {
    pattern: /Willpower(?=\W)/,
    replacement: Icon(StatIconUrl('WILLPOWER'), 'Willpower'),
  },
  {
    pattern: /\(\|\)/,
    replacement: Icon('/icons/SauronsEye.svg', 'Sauron’s Eye'),
  },

  {
    pattern: /Leadership/,
    replacement: <Leadership style={{ ...svgStyle }} />,
  },
  {
    pattern: /Lore/,
    replacement: <Lore style={{ ...svgStyle }} />,
  },
  {
    pattern: /Spirit/,
    replacement: <Spirit style={{ ...svgStyle }} />,
  },
  {
    pattern: /Tactics/,
    replacement: <Tactics style={{ ...svgStyle }} />,
  },
]

const formatCardText = (text: string) => {
  let output: (string | Element)[] = [text]

  textReplacements.forEach(({ pattern, replacement }) => {
    output = output.flatMap((part) => {
      if (typeof part !== 'string') return [part] as (string | Element)[]

      return regexifyString({
        pattern,
        decorator: () => replacement,
        input: part,
      }) as (string | Element)[]
    })
  })

  return <>{output}</>
}

export { formatCardText, formatNumber }
