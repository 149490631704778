import { ReactElement, SVGProps } from 'react'
import { ReactComponent as A_shadow_in_the_east_deluxe_expansion } from '../svgs/expansion symbols/a_shadow_in_the_east_deluxe_expansion.svg'
import { ReactComponent as Against_the_shadow_cycle } from '../svgs/expansion symbols/against_the_shadow_cycle.svg'
import { ReactComponent as Angmar_awakened_campaign_expansion } from '../svgs/expansion symbols/angmar_awakened_campaign_expansion.svg'
import { ReactComponent as Angmar_awakened_cycle } from '../svgs/expansion symbols/angmar_awakened_cycle.svg'
import { ReactComponent as Angmar_awakened_hero_expansion } from '../svgs/expansion symbols/angmar_awakened_hero_expansion.svg'
import { ReactComponent as Core_set } from '../svgs/expansion symbols/core_set.svg'
import { ReactComponent as Defenders_of_gondor_starter_deck } from '../svgs/expansion symbols/defenders_of_gondor_starter_deck.svg'
import { ReactComponent as Dream_chaser_cycle } from '../svgs/expansion symbols/dream-chaser_cycle.svg'
import { ReactComponent as Dream_chaser_campaign_expansion } from '../svgs/expansion symbols/dream_chaser_campaign_expansion.svg'
import { ReactComponent as Dream_chaser_hero_expansion } from '../svgs/expansion symbols/dream_chaser_hero_expansion.svg'
import { ReactComponent as Dwarrowdelf_cycle } from '../svgs/expansion symbols/dwarrowdelf_cycle.svg'
import { ReactComponent as Dwarves_of_durin_starter_deck } from '../svgs/expansion symbols/dwarves_of_durin_starter_deck.svg'
import { ReactComponent as Elves_of_lorien_starter_deck } from '../svgs/expansion symbols/elves_of_lorien_starter_deck.svg'
import { ReactComponent as Ered_mithrin_cycle } from '../svgs/expansion symbols/ered_mithrin_cycle.svg'
import { ReactComponent as Haradrim_cycle } from '../svgs/expansion symbols/haradrim_cycle.svg'
import { ReactComponent as Heirs_of_numenor_deluxe_expansion } from '../svgs/expansion symbols/heirs_of_numenor_deluxe_expansion.svg'
import { ReactComponent as Khazad_dum_deluxe_expansion } from '../svgs/expansion symbols/khazad-dum_deluxe_expansion.svg'

import { ReactComponent as Attack_on_dol_guldur } from '../svgs/expansion symbols/pod/attack_on_dol_guldur.svg'
import { ReactComponent as Escape_from_khazad_dum } from '../svgs/expansion symbols/pod/escape_from_khazad_dum.svg'
import { ReactComponent as Fog_on_the_barrow_downs } from '../svgs/expansion symbols/pod/fog_on_the_barrow_downs.svg'
import { ReactComponent as Murder_at_the_prancing_pony } from '../svgs/expansion symbols/pod/murder_at_the_prancing_pony.svg'
import { ReactComponent as The_battle_of_lake_town } from '../svgs/expansion symbols/pod/the_battle_of_lake_town.svg'
import { ReactComponent as The_hunt_for_the_dreadnaught } from '../svgs/expansion symbols/pod/the_hunt_for_the_dreadnaught.svg'
import { ReactComponent as The_massing_at_osgiliath } from '../svgs/expansion symbols/pod/the_massing_at_osgiliath.svg'
import { ReactComponent as The_mines_of_moria } from '../svgs/expansion symbols/pod/the_mines_of_moria.svg'
import { ReactComponent as The_old_forest } from '../svgs/expansion symbols/pod/the_old_forest.svg'
import { ReactComponent as The_ruins_of_belegost } from '../svgs/expansion symbols/pod/the_ruins_of_belegost.svg'
import { ReactComponent as The_siege_of_annuminas } from '../svgs/expansion symbols/pod/the_siege_of_annuminas.svg'
import { ReactComponent as The_stone_of_erech } from '../svgs/expansion symbols/pod/the_stone_of_erech.svg'
import { ReactComponent as The_wizards_quest } from '../svgs/expansion symbols/pod/the_wizards_quest.svg'
import { ReactComponent as The_woodland_realm } from '../svgs/expansion symbols/pod/the_woodland_realm.svg'

import { ReactComponent as Revised_core_set } from '../svgs/expansion symbols/revised_core_set.svg'
import { ReactComponent as Riders_of_rohan_starter_deck } from '../svgs/expansion symbols/riders_of_rohan_starter_deck.svg'
import { ReactComponent as Ring_maker_cycle } from '../svgs/expansion symbols/ring-maker_cycle.svg'
import { ReactComponent as Shadows_of_mirkwood_cycle } from '../svgs/expansion symbols/shadows_of_mirkwood_cycle.svg'
import { ReactComponent as The_black_riders_saga_expansion } from '../svgs/expansion symbols/the_black_riders_saga_expansion.svg'
import { ReactComponent as The_dark_of_mirkwood_scenario_pack } from '../svgs/expansion symbols/the_dark_of_mirkwood_scenario_pack.svg'
import { ReactComponent as The_fellowship_of_the_ring_saga_expansion } from '../svgs/expansion symbols/the_fellowship_of_the_ring_saga_expansion.svg'
import { ReactComponent as The_flame_of_the_west_saga_expansion } from '../svgs/expansion symbols/the_flame_of_the_west_saga_expansion.svg'
import { ReactComponent as The_grey_havens_deluxe_expansion } from '../svgs/expansion symbols/the_grey_havens_deluxe_expansion.svg'
import { ReactComponent as The_hobbit_on_the_doorstep_saga_expansion } from '../svgs/expansion symbols/the_hobbit_on_the_doorstep_saga_expansion.svg'
import { ReactComponent as The_hobbit_over_hill_and_under_hill_saga_expansion } from '../svgs/expansion symbols/the_hobbit_over_hill_and_under_hill_saga_expansion.svg'
import { ReactComponent as The_land_of_shadow_saga_expansion } from '../svgs/expansion symbols/the_land_of_shadow_saga_expansion.svg'
import { ReactComponent as The_lost_realm_deluxe_expansion } from '../svgs/expansion symbols/the_lost_realm_deluxe_expansion.svg'
import { ReactComponent as The_mountain_of_fire_saga_expansion } from '../svgs/expansion symbols/the_mountain_of_fire_saga_expansion.svg'
import { ReactComponent as The_road_darkens_saga_expansion } from '../svgs/expansion symbols/the_road_darkens_saga_expansion.svg'
import { ReactComponent as The_sands_of_harad_deluxe_expansion } from '../svgs/expansion symbols/the_sands_of_harad_deluxe_expansion.svg'
import { ReactComponent as The_treason_of_saruman_saga_expansion } from '../svgs/expansion symbols/the_treason_of_saruman_saga_expansion.svg'
import { ReactComponent as The_two_towers_saga_expansion } from '../svgs/expansion symbols/the_two_towers_saga_expansion.svg'
import { ReactComponent as The_voice_of_isengard_deluxe_expansion } from '../svgs/expansion symbols/the_voice_of_isengard_deluxe_expansion.svg'
import { ReactComponent as The_wilds_of_rhovanion_deluxe_expansion } from '../svgs/expansion symbols/the_wilds_of_rhovanion_deluxe_expansion.svg'
import { ReactComponent as Two_player_limited_edition_starter } from '../svgs/expansion symbols/two_player_limited_edition_starter.svg'
import { ReactComponent as Vengeance_of_mordor_cycle } from '../svgs/expansion symbols/vengeance_of_mordor_cycle.svg'

export default ({
  expansionSymbol,
  ...props
}: {
  expansionSymbol: string
  [x: string]: any
}): ReactElement => {
  switch (expansionSymbol) {
    case 'against_the_shadow_cycle.svg':
      return <Against_the_shadow_cycle {...props} />
    case 'angmar_awakened_campaign_expansion.svg':
      return <Angmar_awakened_campaign_expansion {...props} />
    case 'angmar_awakened_cycle.svg':
      return <Angmar_awakened_cycle {...props} />
    case 'angmar_awakened_hero_expansion.svg':
      return <Angmar_awakened_hero_expansion {...props} />
    case 'dream_chaser_hero_expansion.svg':
      return <Dream_chaser_hero_expansion {...props} />
    case 'dream_chaser_campaign_expansion.svg':
      return <Dream_chaser_campaign_expansion {...props} />
    case 'a_shadow_in_the_east_deluxe_expansion.svg':
      return <A_shadow_in_the_east_deluxe_expansion {...props} />
    case 'core_set.svg':
      return <Core_set {...props} />
    case 'defenders_of_gondor_starter_deck.svg':
      return <Defenders_of_gondor_starter_deck {...props} />
    case 'dream-chaser_cycle.svg':
      return <Dream_chaser_cycle {...props} />
    case 'dwarrowdelf_cycle.svg':
      return <Dwarrowdelf_cycle {...props} />
    case 'dwarves_of_durin_starter_deck.svg':
      return <Dwarves_of_durin_starter_deck {...props} />
    case 'elves_of_lorien_starter_deck.svg':
      return <Elves_of_lorien_starter_deck {...props} />
    case 'ered_mithrin_cycle.svg':
      return <Ered_mithrin_cycle {...props} />
    case 'haradrim_cycle.svg':
      return <Haradrim_cycle {...props} />
    case 'heirs_of_numenor_deluxe_expansion.svg':
      return <Heirs_of_numenor_deluxe_expansion {...props} />
    case 'khazad-dum_deluxe_expansion.svg':
      return <Khazad_dum_deluxe_expansion {...props} />
    case 'revised_core_set.svg':
      return <Revised_core_set {...props} />
    case 'riders_of_rohan_starter_deck.svg':
      return <Riders_of_rohan_starter_deck {...props} />
    case 'ring-maker_cycle.svg':
      return <Ring_maker_cycle {...props} />
    case 'shadows_of_mirkwood_cycle.svg':
      return <Shadows_of_mirkwood_cycle {...props} />
    case 'the_black_riders_saga_expansion.svg':
      return <The_black_riders_saga_expansion {...props} />
    case 'the_dark_of_mirkwood_scenario_pack.svg':
      return <The_dark_of_mirkwood_scenario_pack {...props} />
    case 'the_fellowship_of_the_ring_saga_expansion.svg':
      return <The_fellowship_of_the_ring_saga_expansion {...props} />
    case 'the_flame_of_the_west_saga_expansion.svg':
      return <The_flame_of_the_west_saga_expansion {...props} />
    case 'the_grey_havens_deluxe_expansion.svg':
      return <The_grey_havens_deluxe_expansion {...props} />
    case 'the_hobbit_on_the_doorstep_saga_expansion.svg':
      return <The_hobbit_on_the_doorstep_saga_expansion {...props} />
    case 'the_hobbit_over_hill_and_under_hill_saga_expansion.svg':
      return <The_hobbit_over_hill_and_under_hill_saga_expansion {...props} />
    case 'the_land_of_shadow_saga_expansion.svg':
      return <The_land_of_shadow_saga_expansion {...props} />
    case 'the_lost_realm_deluxe_expansion.svg':
      return <The_lost_realm_deluxe_expansion {...props} />
    case 'the_mountain_of_fire_saga_expansion.svg':
      return <The_mountain_of_fire_saga_expansion {...props} />
    case 'the_road_darkens_saga_expansion.svg':
      return <The_road_darkens_saga_expansion {...props} />
    case 'the_sands_of_harad_deluxe_expansion.svg':
      return <The_sands_of_harad_deluxe_expansion {...props} />
    case 'the_treason_of_saruman_saga_expansion.svg':
      return <The_treason_of_saruman_saga_expansion {...props} />
    case 'the_two_towers_saga_expansion.svg':
      return <The_two_towers_saga_expansion {...props} />
    case 'the_voice_of_isengard_deluxe_expansion.svg':
      return <The_voice_of_isengard_deluxe_expansion {...props} />
    case 'the_wilds_of_rhovanion_deluxe_expansion.svg':
      return <The_wilds_of_rhovanion_deluxe_expansion {...props} />
    case 'two_player_limited_edition_starter.svg':
      return <Two_player_limited_edition_starter {...props} />
    case 'vengeance_of_mordor_cycle.svg':
      return <Vengeance_of_mordor_cycle {...props} />

    case 'pod/attack_on_dol_guldur.svg':
      return <Attack_on_dol_guldur {...props} />
    case 'pod/escape_from_khazad_dum.svg':
      return <Escape_from_khazad_dum {...props} />
    case 'pod/fog_on_the_barrow_downs.svg':
      return <Fog_on_the_barrow_downs {...props} />
    case 'pod/murder_at_the_prancing_pony.svg':
      return <Murder_at_the_prancing_pony {...props} />
    case 'pod/the_battle_of_lake_town.svg':
      return <The_battle_of_lake_town {...props} />
    case 'pod/the_hunt_for_the_dreadnaught.svg':
      return <The_hunt_for_the_dreadnaught {...props} />
    case 'pod/the_massing_at_osgiliath.svg':
      return <The_massing_at_osgiliath {...props} />
    case 'pod/the_mines_of_moria.svg':
      return <The_mines_of_moria {...props} />
    case 'pod/the_old_forest.svg':
      return <The_old_forest {...props} />
    case 'pod/the_ruins_of_belegost.svg':
      return <The_ruins_of_belegost {...props} />
    case 'pod/the_siege_of_annuminas.svg':
      return <The_siege_of_annuminas {...props} />
    case 'pod/the_stone_of_erech.svg':
      return <The_stone_of_erech {...props} />
    case 'pod/the_wizards_quest.svg':
      return <The_wizards_quest {...props} />
    case 'pod/the_woodland_realm.svg':
      return <The_woodland_realm {...props} />

    default:
      return <></>
  }
}
